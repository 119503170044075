import { useAppDispatch, useAppSelector } from "app/hook";
import { profileInfo } from "app/slices/authSlices/authSlices";
import { authData } from "app/states/auth";
// import { fetchProfileInfo } from "app/slices/profileSlices/profileSlices";
// import { profileData } from "app/states/profile";
import { useFormik } from "formik";
// import { TOAST_MESSAGES } from "global/constants";
import { get } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ProfileServices from "services/api/profile";
// import ProfileServices from "services/api-services/profileServices";

const useConnect = () => {
  const dispatch = useAppDispatch();
  const INFO = useAppSelector(authData);
  const [open, setOpen] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState("");
  const {t} = useTranslation();

  const qrCodeFun = useCallback(() => {
    ProfileServices.QrCode().then(async (res: any) => {
      if (res.data) {
        setQrCode(res.data.data);
      }
    });
  }, []);

  const [isConnact, setIsConnact] = useState<string|boolean>(
    get(INFO, "userData.data.isEnabled2FA", "")
  );
  const delete2Fa = useCallback(() => {
    ProfileServices.Off2FA().then((res) => {
      if (res.status) {
        setIsConnact(false);
      }
    });
  }, []);

  const onSubmit = (values: any) => {
    console.log("values",values)
    try {
      ProfileServices.On2FA( { ...values } ).then((res: any) => {
        if (res.status === 200) {
          dispatch(profileInfo());
          setOpen(false);
          window.location.reload();
          setIsConnact(true);
          toast.success(t("success"));
        } else {
          toast.error("error");
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  // push changes
  console.log("INFO",INFO)
  const initialValues = useMemo(() => {
    const res = {
      email: get(INFO, "userData.data.email", ""),
      code: "",
    };
    return res;
  }, [INFO]);

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });
  useEffect(() => {
    dispatch(profileInfo());
  }, [dispatch]);

  console.log(values.code, "open");
  return {
    values,
    handleSubmit,
    handleChange,
    open,
    setOpen,
    qrCode,
    qrCodeFun,
    setFieldValue,
    isConnact,
    delete2Fa,
  };
};

export default useConnect;
