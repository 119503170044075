import homeServices from "services/api/home";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { initialState } from "./types";

const InitialState: initialState = {
    homeData: {
        data: {},
        loading: false,
        error: ''
    },
    totalStats: {
        data: {
            totalStudents: 0,
            totalSessions: 0,
            newStudentsDaily: 0,
            newStudentsMonthly: 0,
            sessionsFree: 0,
            sessionsPaid: 0
        },
        loading: false,
        error: ''
    }
};

export const fetchHomeData = createAsyncThunk(
    "home/fetchHomeData",
    async () => {
        const request = await homeServices.dashboard();
        if (get(request, "status", "") != 200) {
            return get(request, "message", "");
        }
        const respond = await request.data;
        return respond;
    }
);
export const fetchTotalStats = createAsyncThunk(
    "home/fetchTotalStats",
    async () => {
        const request = await homeServices.dashboardStats();
        if (get(request, "status", "") != 200) {
            return get(request, "message", "");
        }
        const respond = await request.data;
        return respond;
    }
)


const homeSlices = createSlice({
    name: "home",
    initialState: InitialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // home data
        builder.addCase(fetchHomeData.pending, (state) => {
            state.homeData = {
                data: {},
                loading: true,
                error: ''
            }
        });
        builder.addCase(fetchHomeData.fulfilled, (state, actions) => {
            state.homeData = {
                data: get(actions, "payload.data", {}),
                loading: false,
                error: ''
            }
        });
        builder.addCase(fetchHomeData.rejected, (state, actions) => {
            state.homeData = {
                data: {},
                loading: false,
                error: get(actions, "error.message", '')
            }
        });
        // stats 
        builder.addCase(fetchTotalStats.pending, (state) => {
            state.totalStats = {
                data: {
                    newStudentsDaily: 0,
                    newStudentsMonthly: 0,
                    sessionsFree: 0,
                    sessionsPaid: 0,
                    totalSessions: 0,
                    totalStudents: 0,
                },
                loading: true,
                error: ''
            }
        });
        builder.addCase(fetchTotalStats.fulfilled, (state, actions) => {
            state.totalStats = {
                data: get(actions, "payload.data", {}),
                loading: false,
                error: ''
            }
        });
        builder.addCase(fetchTotalStats.rejected, (state, actions) => {
            state.totalStats = {
                data: {
                    newStudentsDaily: 0,
                    newStudentsMonthly: 0,
                    sessionsFree: 0,
                    sessionsPaid: 0,
                    totalSessions: 0,
                    totalStudents: 0,
                },
                loading: false,
                error: get(actions, "error.message", '')
            }
        });

    },
});

export const { } = homeSlices.actions;
export default homeSlices.reducer;
