import localStorage from "redux-persist/lib/storage";

// root persist reducers
const rootPersistConfig = {
  key: "root",
  storage: localStorage,
  blacklist: [
    "menu",
    "profle",
    "users",
    "speaking",
  ], // those reducers will not be persisted
  whiteList: [], // those reducers will be persisted only
};

export default rootPersistConfig;
