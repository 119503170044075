import ProfileInput from "components/ProfilInput";
import { Text } from "@mantine/core";
import { Col, Row } from "react-grid-system";
import { get } from "lodash";
import { BaseButton } from "components";
import useConnectInfo from "./connect";
import { useLocation } from "react-router-dom";
import { PROFILE_CONSTANTS } from "modules/profile/constants";
import { useTranslation } from "react-i18next";

function InfoPage() {
  const {
    // region,
    // districts,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
  } = useConnectInfo();

  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <form
      onSubmit={handleSubmit}
      style={{ marginTop: "20px", marginBottom: "20px", marginLeft: "10px" }}
    >
      <Row>
        <Col xs={12}>
          <ProfileInput
            type="text"
            inputXS={5}
            label={t(PROFILE_CONSTANTS.FIRST_NAME)}
            error={get(errors, "firstName", "") && get(touched, "firstName", "")}
            value={get(values, "firstName", "")}
            handleOnChange={(e: any) =>
              setFieldValue("firstName", get(e, "target.value", ""))
            }
          />

          <ProfileInput
            type="text"
            inputXS={5}
            label={t(PROFILE_CONSTANTS.LAST_NAME)}
            error={get(errors, "lastName", "") && get(touched, "lastName", "")}
            value={get(values, "lastName", "")}
            handleOnChange={(e: any) =>
              setFieldValue("lastName", get(e, "target.value", ""))
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={2.5}></Col>
        <BaseButton
          loading={isSubmitting}
          type="submit"
          style={{
            width: "109px",
            height: "40px",
            marginTop: "5vh",
            backgroundColor: "#0085FF",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          {t(PROFILE_CONSTANTS.UPDATE)}
        </BaseButton>
      </Row>
    </form>
  );
}

export default InfoPage;
