export const USERES_PAGE = {
    USERS: "Users",
    ALL: "All",
    ACTIONS: "actions",
    SESSIONS: "sessions",
    PROFILE: "profile",
    TOTAL_USERS: 'total_users',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    DELETED: 'deleted',
    BLOCKED: 'blocked',
    LAST_SIGNUPS_IN_24_HOURS: 'last_signups_in_24_hours',
    APPLY: 'apply',
    RESET: 'reset',
    SEARCH_INPUT: 'search_input',
    STATUS: 'status',
    EMAIL: "email",
    SESSIONS_FREE: "sessions_free",
    SESSION_PAID: "sessions_paid",
    SIGN_UP_DATE: "sign_up_date",
    LAST_ACTIVE: "last_active",
    DEVICE_TYPE:"device_type",
    SHOW: "show",
    ON_THE_PAGE: "on_the_page"
}