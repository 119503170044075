import { NavLink, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { MenuItemWrapper } from "./style";
import { Flex } from "@mantine/core";
import { useTranslation } from "react-i18next";

const MenuItem = ({
  name,
  icon = "",
  url = "#",
  redirectURL = null,
  key = "",
  allowed,
  ...props
}: any) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  return allowed ? (
    <MenuItemWrapper key={key} {...props}>
      <NavLink
        to={url}
        className={`${pathname === url ? "active" : ""} menu-item `}
      >
        <Flex>
          <ReactSVG src={icon} className={"menu-icon"} />
          <span>{t(name)}</span>
        </Flex>
      </NavLink>
    </MenuItemWrapper>
  ) : (
    <></>
  );
};

export default MenuItem;
