import { BaseButton, FormInput } from "components";

import { Col, Row } from "react-grid-system";
import ConnectPassword from "./connectPassword";
import { Text } from "@mantine/core";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { PROFILE_CONSTANTS } from "modules/profile/constants";

function UpdatePassword() {
  const { values, handleSubmit, handleChange, isSubmitting, errors, touched } =
    ConnectPassword();
  const { t } = useTranslation();
  return (
    <form
      onSubmit={handleSubmit}
      style={{ marginLeft: "10px", marginTop: "20px" }}
    >
      <div style={{ marginBottom: "20px" }}>
        <Row >
          <Col xs={2.5}>
            <Text
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color:
                  get(errors, "currentPassword", false) &&
                  get(touched, "currentPassword", false)
                    ? "#f96565"
                    : "#585757",
              }}
            >
              {/* Действующий пароль */}
              {t(PROFILE_CONSTANTS.CURRENT_PASSWORD)}
            </Text>
          </Col>
          <Col xs={5} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <FormInput
              bg={true}
              error={
                get(errors, "currentPassword", false) &&
                get(touched, "currentPassword", false)
              }
              type="password"
              name="currentPassword"
              placeholder="*** *** ***"
              handleOnChange={handleChange}
              value={values.currentPassword}
            />
          </Col>
        </Row>
        <Row align="center" className="mt-8">
          <Col xs={2.5}>
            <Text
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color:
                  get(errors, "newPassword", false) &&
                  get(touched, "newPassword", false)
                    ? "#f96565"
                    : "#585757",
              }}
            >
              {t(PROFILE_CONSTANTS.NEW_PASSWORD)}

              {/* Новый пароль */}
            </Text>
          </Col>
          <Col xs={5} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <FormInput
              bg={true}
              error={
                get(errors, "newPassword", false) &&
                get(touched, "newPassword", false)
              }
              type="password"
              name="newPassword"
              placeholder="*** *** ***"
              handleOnChange={handleChange}
              value={values.newPassword}
            />
          </Col>
        </Row>
        <Row className="mt-8" align="center">
          <Col xs={2.5}>
            <Text
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color:
                  get(errors, "confirmPassword", false) &&
                  get(touched, "confirmPassword", false)
                    ? "#f96565"
                    : "#585757",
              }}
            >
              {t(PROFILE_CONSTANTS.CONFIRM_PASSWORD)}
              {/* Подтвердите пароль */}
            </Text>
          </Col>
          <Col xs={5} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <FormInput
              bg={true}
              error={
                get(errors, "confirmPassword", false) &&
                get(touched, "confirmPassword", false)
              }
              type="password"
              name="confirmPassword"
              placeholder="*** *** ***"
              handleOnChange={handleChange}
              value={values.confirmPassword}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={2.5}></Col>
          <BaseButton
            type="submit"
            loading={isSubmitting}
            style={{
              width: "109px",
              height: "40px",
              marginTop: "5vh",
              backgroundColor: "#0085FF",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            {t(PROFILE_CONSTANTS.UPDATE)}
          </BaseButton>
        </Row>
      </div>
    </form>
  );
}

export default UpdatePassword;
