import { useAppDispatch, useAppSelector } from "app/hook";
import { profileInfo } from "app/slices/authSlices/authSlices";
import { authData } from "app/states/auth";
import { get } from "lodash";
import { useEffect } from "react";

const useMyAccount = () => {
  const userAuthData = useAppSelector(authData);
  const profile = get(userAuthData, "userData.data", {});
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(profileInfo());
  }, []);
  return {
    profile,
  };
};
export default useMyAccount;
