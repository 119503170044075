import React, { ButtonHTMLAttributes, MouseEventHandler } from "react";
// import { ReactSVG } from "react-svg";
import chevronIcon from "../../assets/icons/chevron.svg";
import { StyledBaseButton } from "./style";
import { StyledBaseButtonProps } from "./types";
import { ReactSVG } from "react-svg";

interface BaseButtonProps
  extends StyledBaseButtonProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
}

const BaseButton: React.FC<BaseButtonProps> = ({
  children,
  outlined = false,
  type = "button",
  loading = false,
  handleClick = () => {},
  disabled,
  ...props
}) => {
  return (
    <StyledBaseButton
      loading={loading}
      onClick={handleClick}
      outlined={outlined}
      type={type}
      disabled={disabled}
      {...props}
    >
      {children}
      {outlined && <ReactSVG className={"chevron"} src={chevronIcon as any} />}
    </StyledBaseButton>
  );
};

export default BaseButton;
