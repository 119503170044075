import { USERES_PAGE } from "modules/users/constants";
import { Col, Row } from "react-grid-system"
import { Outlet } from "react-router-dom"
import users from "assets/icons/users.svg"
import { useTranslation } from "react-i18next";
import { Main, USERS } from "shared/constants/path";
import { MenuItem } from "components";
import { get } from "lodash";
import { SESSIONS_PAGE } from "modules/speaking/constants";
import { INNERLAYOUTS } from "./constants";
function WritingLayout() {
    const { t } = useTranslation();
    const arr = [
        {
            name: t(INNERLAYOUTS.ALL),
            image: users,
            url: Main.WRITING,
        }
    ];
    return (
        <Row>
            <Col xs={2} style={{ marginTop: "35px" }}>
                {arr.map((item: any, index: number) => {
                    return (
                        <MenuItem
                            width={"100%"}
                            key={get(item, "url", index + 1)}
                            url={item.url}
                            activeUrls={item.url}
                            icon={item.image}
                        >
                            {item.name}
                        </MenuItem>
                    );
                })}
            </Col>
            <Col xs={10}>
                <Outlet />
            </Col>
        </Row>
    )
}

export default WritingLayout