import { useAppDispatch, useAppSelector } from "app/hook"
import { fetchHomeData, fetchTotalStats } from "app/slices/homeSlices/homeSlices";
import { authData } from "app/states/auth";
import { homeData, totals } from "app/states/home";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const useConnect = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const data = useAppSelector(homeData);
    const {userData} = useAppSelector(authData)
    const total = useAppSelector(totals);
    // const data = [
    //     {

    //         "month": "SEPTEMBER",
    //         "year": 2024,
    //         "localDate": "2024-09-01",
    //         "sum": "0.0"
    //     },
    //     {
    //         "month": "AUGUST",
    //         "year": 2024,
    //         "localDate": "2024-08-01",
    //         "sum": "0.0"
    //     },
    //     {
    //         "month": "JULY",
    //         "year": 2024,
    //         "localDate": "2024-07-01",
    //         "sum": "0.0"
    //     },
    //     {
    //         "month": "JUNE",
    //         "year": 2024,
    //         "localDate": "2024-06-01",
    //         "sum": "0.0"
    //     },
    //     {
    //         "month": "MAY",
    //         "year": 2024,
    //         "localDate": "2024-05-01",
    //         "sum": "0.0"
    //     },
    //     {
    //         "month": "APRIL",
    //         "year": 2024,
    //         "localDate": "2024-04-01",
    //         "sum": "0.0"
    //     },
    //     {
    //         "month": "MARCH",
    //         "year": 2024,
    //         "localDate": "2024-03-01",
    //         "sum": "0.0"
    //     },
    //     {
    //         "month": "FEBRUARY",
    //         "year": 2024,
    //         "localDate": "2024-02-01",
    //         "sum": "1.215055497E7"
    //     },
    //     {
    //         "month": "JANUARY",
    //         "year": 2024,
    //         "localDate": "2024-01-01",
    //         "sum": "1.5208148549999997E7"
    //     },
    //     {
    //         "month": "DECEMBER",
    //         "year": 2023,
    //         "localDate": "2023-12-01",
    //         "sum": "1.5203977370000001E7"
    //     },
    //     {
    //         "month": "NOVEMBER",
    //         "year": 2023,
    //         "localDate": "2023-11-01",
    //         "sum": "1.7788144889999997E7"
    //     },
    //     {
    //         "month": "OCTOBER",
    //         "year": 2023,
    //         "localDate": "2023-10-01",
    //         "sum": "5845350.39"
    //     }
    // ].map(el => ({ ...el, sum: Number(el.sum) / 1000 })).reverse();

    useEffect(() => {
        dispatch(fetchHomeData());
        dispatch(fetchTotalStats());
    }, []);
    const LAST_NOTIFIES = [
        {
            "title": "Sayra.uz hamisha siz bilan",
            "id": "46c5f061-7001-4c43-b2f5-99bb74c990f4",
            "language": "RU",
            "author": null,
            "deliveryCount": 100,
            "countryName": "Uzbekistan",
            "displayId": 1115,
            "openedCount": 76,
            "sentDate": "2024-02-21T09:35:41.509"
        },
        {
            "title": "Navro’z bayrami bilan tabriklaymiz",
            "id": "04d2daae-6aa5-4eef-8583-114a64b891ef",
            "language": "RU",
            "author": null,
            "deliveryCount": 100,
            "countryName": "Uzbekistan",
            "displayId": 1114,
            "openedCount": 23,
            "sentDate": "2024-02-21T09:07:58.722"
        }
    ]
   
    return {
        data,
        LAST_NOTIFIES,
        navigate,
        userData,
        total
    }
}

export default useConnect

