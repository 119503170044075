export enum Main {
  HOME = "/",
  PROFILE = "/profile",
  USERS = "/users",
  SPEAKING = "/speaking",
  WRITING = "/writing",
  LOGIN = "/login",
  PUSH = "/push",
}
export enum Auth {
  LOGIN = "/login",
  RESET_PASSWORD = "/reset-password",
  CHANGE_EMAIL = "/change-email",
  RELOAD = "/auth-reload",
}
export enum PROFILE {
  MY_PROFILE = "/profile",
  PAYMENT_METHOD = "/profile/payment-method",
  LOGOUT = "/logout",
}
export enum USERS {
  USERS = "/users",
  SESSIONS = "/sessions",
  PROFILE = "/profile",
}
