import Demo from "./components/chart/AreaChart";
import { Col, Container, Row } from "react-grid-system";
import { BaseButton, BaseProgress, BaseTable, Content, Flex, Loading, Text, Title } from "components";
import { HOMECONSTANT } from "./constants";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import check1 from "assets/icons/check_circle.svg";
import check2 from "assets/icons/check_circle (1).svg";
import { ReactSVG } from "react-svg";
import useConnect from "./connect";
import { get, isEmpty, isEqual } from "lodash";
import BaseBreadcrumb from "components/Bredcrump";
function Home() {
  const { t } = useTranslation();
  const { data, LAST_NOTIFIES, navigate, userData, total } = useConnect();


  return (
    <Container fluid style={{ padding: " 0px  10vh" }}>
      <Row className="mt-8 ml-16">
        <BaseBreadcrumb items={[
          { url: '', name: t(HOMECONSTANT.TITLE), id: 1 }
        ]} />
      </Row>
      <Row className="ml-16" style={{ marginTop: '-10px', marginBottom: '10px' }}>
        <Text gray >
          {t(HOMECONSTANT.DISCRIPTION)}{" "} {get(userData, 'data.firstName', '') + " " + get(userData, 'data.lastName', '')}
        </Text>
      </Row>
      {get(data, "loading", false) && get(total, 'loading', false) ? (
        <Loading />
      ) : (
        <>
          <Content height={"300px"}>
            <Row className="mt-8">
              <Col xs={10.5}>
                <Text xl style={{ marginLeft: "30px", marginBottom: '10px' }}>
                  {t(HOMECONSTANT.CHARTS_TITLE)}
                </Text>
                <Demo lines={
                  [
                    { stroke: '#ff6e00', dataKey: 'USD' },
                    { stroke: '#0084ff', dataKey: 'UZS' },
                  ]} data={get(data, 'data.charts')} />
              </Col>
              <Col xs={1.5} style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Text
                    xl
                    style={{
                      fontSize: "px",
                      fontWeight: "500",
                    }}
                  >

                    <NumericFormat
                      displayType="text"
                      value={get(data, 'data.totalPaymentUSD', 0)}
                      thousandSeparator={true}
                      suffix=" USD"
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </Text>{" "}
                  <Text
                    xl
                    style={{
                      fontSize: "px",
                      fontWeight: "500",
                      marginTop: '20px'
                    }}
                  >

                    <NumericFormat
                      displayType="text"
                      value={get(data, 'data.totalPaymentUZS', 0)}
                      thousandSeparator={true}
                      suffix=" UZS"
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </Text>{" "}
                  <br />
                  <Text style={{ color: "#969696", marginTop: '20px' }} large>
                    {t(HOMECONSTANT.SALES_THIS_MONTH)}
                  </Text>
                  <BaseButton
                    handleClick={() =>
                      navigate('/sessions')
                    }
                    outlined
                    // dark
                    className="mt-8"
                    width={"80px"}
                  >
                    <Text xs>{t(HOMECONSTANT.ALL_SALES_SUMMARY)}</Text>
                  </BaseButton>
                </div>
              </Col>
            </Row>
          </Content>
          <Row className="mt-32 mb-16">
            <Col xs={6}>
              <Content height={"100%"} className={"mb-16"} rounded>
                <Row className="p-4"> <Title md>{t(HOMECONSTANT.STATUS)}</Title></Row>
                <Row className="p-4">
                  <Col className="" xs={6}>
                    <Title lg >{get(total, 'data.totalStudents', 0).toLocaleString()}</Title>
                    <Text gray>{t(HOMECONSTANT.TOTAL_STUDENTS)}</Text>
                  </Col>
                  <Col className="" xs={6}>
                    <Title lg>{Number(get(total, 'data.totalSessions', 0)).toLocaleString()}</Title>
                    <Text gray>{t(HOMECONSTANT.TOTAL_SESSIONS)}</Text>
                  </Col>
                  <Col className="mt-16" xs={6}>
                    <Title lg>{Number(get(total, 'data.newStudentsDaily', 0)).toLocaleString()}</Title>
                    <Text gray>{t(HOMECONSTANT.NEW_STUDENTS_DAILY)}</Text>
                  </Col>
                  <Col className="mt-16" xs={6}>
                    <Title lg>{Number(get(total, 'data.newStudentsMonthly', 0)).toLocaleString()}</Title>
                    <Text gray>{t(HOMECONSTANT.NEW_STUDENTS_MONTHLY)}</Text>
                  </Col>
                  <Col className="mt-16" xs={6}>
                    <Title lg>{Number(get(total, 'data.sessionsFree', 0)).toLocaleString()}</Title>
                    <Text gray>{t(HOMECONSTANT.SESSIONS_FREE)} {" ("}{t(HOMECONSTANT.MONTHLY)}{")"}</Text>
                  </Col>
                  <Col className="mt-16" xs={6}>
                    <Title lg>{Number(get(total, 'data.sessionsPaid', 0)).toLocaleString()}</Title>
                    <Text gray>{t(HOMECONSTANT.SESSIONS_PAID)}{" ("}{t(HOMECONSTANT.MONTHLY)}{")"}</Text>
                  </Col>
                </Row>
              </Content>
            </Col>
            <Col xs={6}>
              <Content height={"100%"} className={"mb-16"} rounded>
                <Row align={"center"} className={""}>
                  <Col xs={8}>
                    {" "}
                    <Text xl dark medium>
                      {t(HOMECONSTANT.LAST_SENT_PUSH_POTIFICATIONS)}
                    </Text>
                  </Col>
                  <Col xs={4} className={"text-right"}>
                    <BaseButton
                      handleClick={() =>
                        navigate('/push')
                      }
                      outlined
                      // dark
                      width={"80px"}
                    >
                      <Text xs>See All</Text>
                    </BaseButton>
                  </Col>
                </Row>
                {LAST_NOTIFIES.map((notification: any, index: any) => (
                  <div key={index}>
                    <Row align={"center"}>
                      <Col xs={2}>
                        <Flex justify={"center"}>
                          <BaseProgress
                            percentage={get(
                              notification,
                              "openedCount",
                              0
                            )}
                          />
                        </Flex>
                      </Col>
                      <Col xs={10}>
                        <Flex className={"mb-8"}>
                          <Text
                            gray
                            className={
                              "mr-8 cursor-pointer"
                            }
                          // onClick={() =>
                          //     gotTo({
                          //         url:
                          //             URL_WITH_ID_NOTIF +
                          //             get(
                          //                 notification,
                          //                 "id",
                          //                 ""
                          //             ),
                          //     })
                          // }
                          >
                            {">"}
                          </Text>

                          <Text gray>
                            {" "}
                            {get(notification, "title", "")}
                          </Text>
                        </Flex>
                        <Flex align="center" className={"mb-8"}>
                          <ReactSVG
                            className={"check-icon"}
                            src={check1 as any}
                          />
                          <Text gray>
                            {" "}
                            {t(HOMECONSTANT.DELIVERED)}:{" "}
                            <NumericFormat
                              displayType="text"
                              value={get(
                                notification,
                                "deliveryCount",
                                ""
                              )}
                              thousandSeparator
                            />
                          </Text>
                        </Flex>
                        <Flex align="center">
                          <ReactSVG
                            className={"check-icon"}
                            src={check2 as any}
                          />
                          <Text gray>
                            {" "}
                            {t(HOMECONSTANT.OPENED)}:{" "}
                            <NumericFormat
                              displayType="text"
                              value={get(
                                notification,
                                "openedCount",
                                ""
                              )}
                              thousandSeparator
                            />
                          </Text>
                        </Flex>
                      </Col>
                    </Row>
                    {!isEqual(
                      index - 1,
                      get({}, "notificationData", []).length
                    ) && (
                        <Row className={"mt-24 mb-24"}>
                          <Col xs={12}>
                            <hr className={"horizontal-line"} />
                          </Col>
                        </Row>
                      )}
                  </div>
                ))}
              </Content>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default Home;
