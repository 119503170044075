import request from "..";

class pushServices {
    static getNitficationHistory = (params: any) => {
        return request.get(`/api/notification`, { params });
    };
    static getByIdNotfication = (id: any) => {
        return request.get(`/api/notification/${id}`);
    };
    static postNotfication = (params: any) => {
        return request.post(`/api/notification`, { ...params });
    };
}

export default pushServices;
