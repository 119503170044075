import styled from "styled-components";
import { css } from "styled-components";
import { StyledProps } from "./type";

const TitleWrapper = styled.h2<StyledProps>`
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 500;
  padding: ${({ padding }: StyledProps) => padding || "0px"};
  margin: ${({ margin }: StyledProps) => margin || "0px"};
  ${({ lg }: StyledProps) =>
    lg &&
    css`
      font-size: 32px;
    `};

  ${({ xlg }: StyledProps) =>
    xlg &&
    css`
      font-size: 36px;
    `};
  ${({ md }: StyledProps) =>
    md &&
    css`
      font-size: 24px;
    `};
  ${({ sm }: StyledProps) =>
    sm &&
    css`
      font-size: 20px;
    `};
  ${({ xsm }: StyledProps) =>
    xsm &&
    css`
      font-size: 14px;
    `};
  ${({ xxsm }: StyledProps) =>
    xxsm &&
    css`
      font-size: 12px;
    `};
  ${({ gray }: StyledProps) =>
    gray &&
    css`
      color: #585757;
    `};
  ${({ bold }: StyledProps) =>
    bold &&
    css`
      font-weight: 700;
    `}

  ${({ green }: StyledProps) =>
    green &&
    css`
      color: rgba(0, 162, 65, 1);
    `}
    ${({ opasit }: StyledProps) =>
    opasit &&
    css`
      color: #969696;
    `}

    @media (max-width: 1600px) {
    /* font-size: 18px; */
  }
`;

export { TitleWrapper };
