import homeServices from "services/api/home";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { initialState } from "./types";
import speakingServices from "services/api/speaking";

const InitialState: initialState = {
    speaking: {
        data: [],
        pagination: {},
        loading: false,
        error: ''
    },
    totals: {
        data: {},
        loading: false,
        error: ''
    },
    filter: {
        page: 0,
        size: 10,
        search: '',
        status: ''
    }
};

export const fetchGetAllSpeaking = createAsyncThunk(
    "speaking/fetchGetAllSpeaking",
    async (params: any) => {
        const request = await speakingServices.getAllSpeaking(params);
        if (get(request, "status", "") != 200) {
            return get(request, "message", "");
        }
        const { data, pagination } = await request.data;
        return { data, pagination };
    }
);
export const fetchGetTotalSpeaking = createAsyncThunk(
    "speaking/fetchGetTotalSpeaking",
    async () => {
        const request = await homeServices.dashboardSession();
        if (get(request, "status", "") != 200) {
            return get(request, "message", "");
        }
        const respond = await request.data;
        return respond;
    }
)


const speakingSlices = createSlice({
    name: "speaking",
    initialState: InitialState,
    reducers: {
        handleFilterSpeaking: (state, action) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value
            }
        },
        handleClearSpeaking: (state) => {
            state.filter = InitialState.filter
        }
    },
    extraReducers: (builder) => {
        // speaking
        builder.addCase(fetchGetAllSpeaking.pending, (state) => {
            state.speaking = {
                data: [],
                pagination: {},
                loading: true,
                error: ''
            }
        });
        builder.addCase(fetchGetAllSpeaking.fulfilled, (state, actions) => {

            state.speaking = {
                data: get(actions, "payload.data", []),
                pagination: get(actions, "payload.pagination", {}),
                loading: false,
                error: ''
            }
        });
        builder.addCase(fetchGetAllSpeaking.rejected, (state, actions) => {
            state.speaking = {
                data: [],
                pagination: {},
                loading: false,
                error: get(actions, "error.message", '')
            }
        });
        // totals
        builder.addCase(fetchGetTotalSpeaking.pending, (state) => {
            state.totals = {
                data: {},
                loading: true,
                error: ''
            }
        });
        builder.addCase(fetchGetTotalSpeaking.fulfilled, (state, actions) => {

            state.totals = {
                data: get(actions, "payload.data", {}),
                loading: false,
                error: ''
            }
        });
        builder.addCase(fetchGetTotalSpeaking.rejected, (state, actions) => {
            state.totals = {
                data: {},
                loading: false,
                error: get(actions, "error.message", '')
            }
        });

    },
});

export const { handleFilterSpeaking, handleClearSpeaking } = speakingSlices.actions;
export default speakingSlices.reducer;
