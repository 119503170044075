import React from "react";
import ReactDOM from "react-dom/client";
import MainRouter from "./routers/MainRouter";
import Theme from "./shared/theme/index";
import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";
import { Provider } from "react-redux";
import store from "./app/store";
import { injectStore } from "./services";
import { AbilityProvider } from "./routers/AbilitySintex";
import './locales/index';
import "moment/dist/locale/ru";
import "moment/dist/locale/uz-latn";

injectStore(store);
// tsc -b && -type over-case.tsx
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <MantineProvider>
      <Theme>
        <AbilityProvider>
          <MainRouter />
        </AbilityProvider>
      </Theme>
    </MantineProvider>
  </Provider>
);
