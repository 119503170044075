import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import rootPersistConfig from "app/persistConfig";
import { menuSlice, homeSlices, authSlices, profileSlices, usersSlices, pushSlices, speakingSlices } from "./slices";

const reducers = combineReducers({
  menu: menuSlice,
  home: homeSlices,
  auth: authSlices,
  profile: profileSlices,
  users: usersSlices,
  speaking: speakingSlices,
  push: pushSlices
});

const persistedReducers = persistReducer(rootPersistConfig, reducers);

export default persistedReducers;
