import { BaseListProps } from "./type";

const BaseList = <T,>({ items = [], children }: BaseListProps<T>) => {
  return (
    <>
      {items.map((item, index) => (
        <span key={index + 1}>{children(item, index)}</span>
      ))}
    </>
  );
};

export default BaseList;
