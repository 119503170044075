import { Content } from "components";
import { Container, Flex, Text } from "@mantine/core";
import { Col, Row } from "react-grid-system";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import InfoPage from "./tabs/infoPage/info";
import useMyAccount from "./useMyAccount";
import { get } from "lodash";
import { UserRole } from "shared/constants/permissions";
import UpdatePassword from "./tabs/password/UpdatePassword";
import BaseBreadcrumb from "components/Bredcrump";
import { useTranslation } from "react-i18next";
import { PROFILE_CONSTANTS } from "../../constants";
import EmailVerify from "./tabs/emailVerify";
function MyAccount() {
  const { profile } = useMyAccount();
  const { t } = useTranslation();
  const items = [
    { url: "", id: "1", name: t(PROFILE_CONSTANTS.SETTINGS) },
    { url: "", id: "2", name: t(PROFILE_CONSTANTS.MY_ACCAUNT) },
  ];
  return (
    <Container fluid>
      <BaseBreadcrumb style={{ marginBottom: "0" }} items={items} />
      <Content className="p-2" height={"83vh"}>
        <Row justify="between" className="px-24 mt-24">
          <Col xs={6}>
            <Flex gap={"lg"} align={"center"}>
              <div
                style={{
                  width: "64px",
                  height: "64px",
                  borderRadius: "50%",
                  border : "1px solid #E5E5E5",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    bottom: "0",
                    right: "0",
                    borderRadius: "50%",
                    position: "absolute",
                    backgroundColor:'#00BA34',
                  }}
                ></div>
              </div>
              <Text style={{ fontSize: "36px" }}>
                {" "}
                {/* {get(profile, "email", "")} */}
                {/* James Bond */}
                {get(profile, "firstName", "")}{" "}{get(profile, "lastName", "")}
              </Text>
            </Flex>
          </Col>
          <Col xs={6} style={{ paddingRight: 0, textAlign: "right" }}>
            <Flex className={"w-100"} justify={"end"} gap={"64px"}>
              <div>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    opacity: "0.5",
                  }}
                >
                  {t(PROFILE_CONSTANTS.ACCAUNT_CATEGORY)}
                  {/* Категория аккаунта */}
                </p>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    marginTop: "4px",
                    color: "#1c1c1c",
                  }}
                >
                  {/* {get(profile, "role", "") === UserRole.ADMIN
                    ? t(PROFILE_CONSTANTS.ADMIN)
                    : t(PROFILE_CONSTANTS.DEALER)} */}
                    Super Admin
                </p>
              </div>
            </Flex>
          </Col>
        </Row>
        <Row className="mt-32">
          <Col xs={12}>
            <Tabs tabIndex={1}>
              <TabList>
                <Tab>{t(PROFILE_CONSTANTS.INFO)}</Tab>
                <Tab> {t(PROFILE_CONSTANTS.UPDATE_PASSWORD)}</Tab>
                <Tab>2FA</Tab>
              </TabList>
              <TabPanel>
                <InfoPage />
              </TabPanel>
              <TabPanel>
                <UpdatePassword />
              </TabPanel>
              <TabPanel>
                <EmailVerify/>
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
      </Content>
    </Container>
  );
}

export default MyAccount;
