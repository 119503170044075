export const GLOBAL_CONSTANTS = {
  SEARCH: "Поиск",
  RESET: "Сбросить",
  CHANGE: "change",
  SHOW: "show",
  ON_PAGE: "on_page",
  ADD: "add",
  CLOSE: "Close",
  EDIT: "Edit",
  CANSEL: "cansel",
  YES: "Yes",
  NO: "No",
  DELETE: "delete",
  END: "Конец",
  START: "Начало",
  NO_DATA: "no_data",
  CANCELLATION: "cancellation",
  BREND: "brand",
  BRAND_NAME: "brand_name",
  YOU_SHURE: "Are_you_sure_you_want_to_delete",
  DATA_ENTRY: "data_entry",
  SER_NUMBER_NOT_FOUND: "serNumber_not_found",
  SUCCESS: "success",
  DELETED: "deleted",
  UPDATED: "updated",
};
