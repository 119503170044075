import { Col, Container, Row } from "react-grid-system";
import { Image, Text } from "@mantine/core";
import { useAppSelector } from "app/hook";
import { navBarMenus } from "app/states/menu";
import MenuItem from "./menuItem";
import { MenuWrapper } from "./style";
import Profile from "../Profile";
import logo from "assets/images/Logo.png";
import { PropsProfile } from "../Profile/type";
import { authData } from "app/states/auth";
import { get } from "lodash";
import { UserRole } from "shared/constants/permissions";
import FlexBox from "../Flex";

const Menu = ({ userName }: PropsProfile) => {
  const menus = useAppSelector(navBarMenus);
  const userAuthData = useAppSelector(authData);
  const user = get(userAuthData, "userData.data.role", false);
  let removeElement = 8;
  if (user === UserRole.DEALER) {
    removeElement = 5;
  }

  return (
    <MenuWrapper>
      <Container fluid>
        <Row align="center">
          <Col xs={2}>
            {/* <Image
              alt="logo"
              src={logo}
              style={{ width: "40px", height: "40px" }}
              fit="cover"
            /> */}
            <Text
              style={{
                color: " #1C1C1C",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "28px",
              }}
              size="lg"
            >
              Sayra App
            </Text>
          </Col>
          <Col xs={8}>
            <FlexBox gap="50px">
              {menus
                .filter((item: any) => item.id !== removeElement)
                .map((menu, index: number) => {
                  if (user === UserRole.DEALER && menu.name === "Склад") {
                    return (
                      <MenuItem
                        key={`${index + 1}/$@#"${index + 202}`}
                        {...menu}
                        // url={'storage/dealer-storage'||'storage'}
                      />
                    );
                  } else {
                    return (
                      <MenuItem
                        key={`${index + 1}/$@#"${index + 202}`}
                        {...menu}
                      />
                    );
                  }
                })}
            </FlexBox>
          </Col>
          <Col xs={2}>
            <Profile userName={userName} />
          </Col>
        </Row>
      </Container>
    </MenuWrapper>
  );
};

export default Menu;
