import { useAppDispatch, useAppSelector } from "app/hook";
import { logIn } from "app/slices/authSlices/authSlices";
import { authData } from "app/states/auth";
import { useFormik } from "formik";
import { get, isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "shared/constants/path";
import { object, string } from "yup";

const useConnect = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const userAuthData = useAppSelector(authData);
  const isAuthenticated = get(userAuthData, "isAuthenticated", false);
  const [is2FA, setIs2FA] = useState(false);
  const onSubmit = (values: any) => {
    dispatch(logIn(values)).then((res: any) => {
      if (isEqual(get(res, "meta.requestStatus", ""), "fulfilled")&&get(res, "payload.data.isEnabled2FA", false)) {
        navigate(Auth.RELOAD, { replace: true });
        setIs2FA(get(res, "payload.data.isEnabled2FA", false));
        setSubmitting(false);
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 1500);
      }
    });
  };
  const userSchema = object().shape({
    email: string().email("Email is invalid").required("Email is required"),
    password: string().required("Password is required"),
    code:is2FA?string().required("Code is required"):string().notRequired(),
  })
  const {
    values,
    setValues,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    errors,
    touched
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      code: "",
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: userSchema,
  });
  const checkUserIsAuth = useCallback(() => {
    if (isAuthenticated) {
      navigate(`/`, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    checkUserIsAuth();
  }, [checkUserIsAuth]);
  return {
    handleSubmit,
    setFieldValue,
    setShowPassword,
    showPassword,
    values,
    setValues,
    isSubmitting,
    errors,
    touched,
    is2FA
    // errorMessage,
  };
};
export default useConnect;
