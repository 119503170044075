import styled, { css } from "styled-components";
import { StyledTextProps } from "./types";

 
 
export const StyledText = styled.span<StyledTextProps>`
    display: inline-block;
    color: #1c1c1c;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: ${({ fontWeight }:StyledTextProps) => fontWeight || "400"};
    padding: ${({ padding }:StyledTextProps) => padding || "0px"};
    margin: ${({ margin }:StyledTextProps) => margin || "0px"};
    ${({ danger }:StyledTextProps) =>
        danger &&
        css`
            color: #e92c2c;
        `};
    ${({ tangerine }:StyledTextProps) =>
        tangerine &&
        css`
            color: #f79e1b;
        `};
    ${({ dark }:StyledTextProps) =>
        dark &&
        css`
            color: #1c1c1c;
        `};
    ${({ light }:StyledTextProps) =>
        light &&
        css`
            color: #fff;
        `};
    ${({ gray }:StyledTextProps) =>
        gray &&
        css`
            color: #969696;
        `};
    ${({ success }:StyledTextProps) =>
        success &&
        css`
            color: #53ac92;
        `};
    ${({ primary }:StyledTextProps) =>
        primary &&
        css`
            color: #0052b4;
        `};
    ${({ blue }:StyledTextProps) =>
        blue &&
        css`
            color: #0085ff;
        `};
    ${({ info }:StyledTextProps) =>
        info &&
        css`
            color: #55acee;
        `};
    ${({ green }:StyledTextProps) =>
        green &&
        css`
            color: #00a241;
        `};
    ${({ lightDark }:StyledTextProps) =>
        lightDark &&
        css`
            color: #585757;
        `};
    ${({ review }:StyledTextProps) =>
        review &&
        css`
            color: rgba(123, 97, 255, 1);
        `};
    ${({ medium }:StyledTextProps) =>
        medium &&
        css`
            font-weight: 500;
        `};
    ${({ bold }:StyledTextProps) =>
        bold &&
        css`
            font-weight: 700;
        `};
    ${({ xxl }:StyledTextProps) =>
        xxl &&
        css`
            font-size: 32px;
        `};
    ${({ xl }:StyledTextProps) =>
        xl &&
        css`
            font-size: 23px;
        `};
    ${({ large }:StyledTextProps) =>
        large &&
        css`
            font-size: 18px;
        `};
    ${({ small }:StyledTextProps) =>
        small &&
        css`
            font-size: 14px;
        `};
    ${({ xs }:StyledTextProps) =>
       Boolean(xs) &&
        css`
            font-size: 12px;
        `};
    ${({ xxs }:StyledTextProps) =>
        Boolean(xxs) &&
        css`
            font-size: 10px;
        `};
`;
