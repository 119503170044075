import React from "react";
import styled from "styled-components";

interface StyledFlexProps {
  align?: string;
  justify?: string;
  column?: boolean;
  gap?: string;
}

const StyledFlex = styled.div<StyledFlexProps>`
  display: flex;
  align-items: ${({ align }:StyledFlexProps) => align || "center"};
  justify-content: ${({ justify }:StyledFlexProps) => justify || "flex-start"};
  flex-direction: ${({ column }:StyledFlexProps) => (column ? "column" : "row")};
  gap: ${({ gap }: any) => gap || "10px"};

  /* gap: 15px; */
`;

const FlexBox: React.FC<
  StyledFlexProps & React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  return <StyledFlex {...props} />;
};

export default FlexBox;
