import { Modal, MantineProvider, Image } from "@mantine/core";
import { propsType } from "./types";
import "@mantine/core/styles.css";
import warining from "assets/images/warning-icon.png";

function Index({
  opened,
  close,
  children,
  title,
  isDelete,
  onClick,
  size,
}: propsType) {
  return (
    <MantineProvider>
      <Modal
        opened={opened}
        size={size}
        w={"max-content"}
        onClose={close}
        onClick={onClick}
        centered
        styles={{
          title: {
            fontSize: "20px",
            fontWeight: "700",
            color: "#1C1C1C",
          },
        }}
      >
        <div style={{ textAlign: "center", minHeight: "10px" }}>
          {isDelete && (
            <>
              <hr style={{ margin: "0 -15px" }} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Image
                  src={warining}
                  alt="404"
                  width={"100px"}
                  m={"auto"}
                  maw={"80px"}
                />
                <p
                  className="mr-32 bold"
                  style={{ fontSize: "", fontWeight: "bold" }}
                >
                  {title}
                </p>
              </div>
              <br />
            </>
          )}
          {children}
        </div>
      </Modal>
    </MantineProvider>
  );
}

export default Index;
