import React from 'react'
import { Col, Row } from 'react-grid-system'
import useConnect from './connect'
import { useTranslation } from 'react-i18next';
import BaseBreadcrumb from 'components/Bredcrump';
import { BaseButton, BaseInput, BasePagination, BaseSelect, BaseTable, Content, DateFormat, Flex, Loading, NoData, Text, WindowHeight } from 'components';
import { get, isEmpty } from 'lodash';
import { ReactSVG } from 'react-svg';
import { WRITING_PAGE } from './constants';
import { Skeleton } from '@mantine/core';
import dots from 'assets/icons/more_horiz.svg';

function Index() {
    const { totals, data } = useConnect();
    const { t } = useTranslation();
    return (
        <Row>
            <Col className="mt-8" xs={12}>
                <BaseBreadcrumb items={[
                    { url: "", name: t(WRITING_PAGE.WRITING), id: 1 },
                ]} />
            </Col>
            <Col xs={12}>
                <Content>
                    <Col xs={12}>
                        <Row style={{ paddingBottom: '10px' }}>
                            <Col xs={1.2}>
                                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                                    <Text large medium><span style={{ color: '#969696', fontSize: '16px' }}>{t(WRITING_PAGE.TOTAL)}: </span>{Number(get(totals, 'data.totalSessions', 0)).toLocaleString()}</Text>}
                            </Col>
                            <Col xs={1.2}>
                                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                                    <Text large medium><span style={{ color: '#969696', fontSize: '16px' }}>{t(WRITING_PAGE.FREE)}: </span>{Number(get(totals, 'data.free', 0)).toLocaleString()}</Text>}
                            </Col>
                            <Col xs={1.2}>
                                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                                    <Text large medium><span style={{ color: '#969696', fontSize: '16px' }}>{t(WRITING_PAGE.PAID)}: </span>{Number(get(totals, 'data.paid', 0)).toLocaleString()}</Text>}
                            </Col>
                            <Col xs={1.7}>
                                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                                    <Text large medium><span style={{ color: '#969696', fontSize: '16px' }}>{t(WRITING_PAGE.FREE_FAILRD)}: </span>{Number(get(totals, 'data.freeFailed', 0)).toLocaleString()}</Text>}
                            </Col>

                            <Col xs={1.7}>
                                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                                    <Text large medium><span style={{ color: '#969696', fontSize: '16px' }}>{t(WRITING_PAGE.PAID_FAILRD)}: </span>{Number(get(totals, 'data.paidFailed', 0)).toLocaleString()}</Text>}
                            </Col>
                            <Col xs={1.9}>
                                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                                    <Text large medium><span style={{ color: '#969696', fontSize: '16px' }}>{t(WRITING_PAGE.FREE_IN_24_HOURS)}: </span>{Number(get(totals, 'data.freeToday', 0)).toLocaleString()}</Text>}
                            </Col>
                            <Col xs={2}>
                                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                                    <Text large medium><span style={{ color: '#969696', fontSize: '16px' }}>{t(WRITING_PAGE.PAID_IN_24_HOURS)}: </span>{Number(get(totals, 'data.paidToday', 0)).toLocaleString()}</Text>}
                            </Col>
                        </Row>
                        <hr style={{ margin: '0 -35px' }} />
                    </Col>
                    <Row justify="between" style={{ padding: '10px 30px' }}>
                        <Flex gap="10px">
                            <BaseInput
                                width="300px"
                                bg
                            //   handleInput={(e: string) => handleFilter({ name: "search", value: e })}
                            //   value={filter.search}
                            //   placeholder={t(SESSIONS_PAGE.SEARCH_INPUT)}
                            />
                            <BaseSelect
                                handleChange={() => { }}
                                //   value={filter.status}
                                width="100px" bg
                                //   placeholder={t(SESSIONS_PAGE.STATUS)} 
                                options={[
                                    // { label: t(SESSIONS_PAGE.ACTIVE), value: "Active" },
                                    // { label: t(SESSIONS_PAGE.INACTIVE), value: "Inactive" },
                                ]} />
                        </Flex>
                        <Flex gap="10px">
                            <BaseButton
                                // onClick={fetchFilterSessions} 
                                primary>{t(WRITING_PAGE.APPLY)}</BaseButton>
                            <BaseButton
                                // onClick={handleReset}
                                danger>{t(WRITING_PAGE.RESET)}</BaseButton>
                        </Flex>
                    </Row>
                    <hr style={{ margin: '0 -20px' }} />
                    <Row>
                        <Col xs={12}>
                            <WindowHeight manual="19.5">
                                {get(data, "loading", false) ? (
                                    <Loading />
                                ) : (
                                    <BaseTable
                                        className="table_scroll"
                                        email={true}
                                        // hasDelete={ability.can(Permissions.DELETE, PAGES.SPARES)}
                                        // hasUpdate={ability.can(Permissions.UPDATE, PAGES.SPARES)}
                                        tableHeader={[
                                            "ID",
                                            `${t(WRITING_PAGE.EMAIL) + " (" + t(WRITING_PAGE.USER) + ")"}`,
                                            t(WRITING_PAGE.SESSION_TYPE),
                                            t(WRITING_PAGE.AMOUNT),
                                            t(WRITING_PAGE.PAYMENT_TYPE),
                                            t(WRITING_PAGE.DATE),
                                            t(WRITING_PAGE.STATUS),
                                            t(WRITING_PAGE.ACTIONS),
                                        ]}
                                    >
                                        {!isEmpty(get(data, "data", [])) ? (
                                            get(data, "data", []).map((item: any, index: number) => (
                                                <tr
                                                    className="pointer"
                                                    key={item.id}

                                                >
                                                    <td>
                                                        {get(item, 'id', '')}
                                                    </td>
                                                    <td style={{ textAlign: 'left' }}>
                                                        {get(item, "email", null)
                                                            ? get(item, "email", "-")
                                                            : "-"}
                                                    </td>

                                                    <td style={{ color: get(item, "session_type", "Paid") === "Free" ? "green" : "#F71B1B" }}>{get(item, "session_type", null) ? get(item, "session_type", "-") : '-'}</td>
                                                    <td>{item.amount ?
                                                        get(item, "amount", "-") : "-"}
                                                    </td>
                                                    <td>{item.payType ? get(item, "payType", "-") : '-'}</td>
                                                    <td>{!get(item, "paid_time", null) ? '-' : <DateFormat date={get(item, "paid_time", "")} />}
                                                    </td>
                                                    <td >{t(get(item, "status", "INACTIVE"))}</td>
                                                    <td>
                                                        <ReactSVG src={dots as any} />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <NoData colLength={8} />
                                        )}
                                    </BaseTable>
                                )}
                            </WindowHeight>
                        </Col>
                        <Col xs={12} style={{ marginTop: "10px", alignItems: "center", justifyContent: 'space-between', display: "flex" }}>
                            <Flex gap="10px">
                                <Text>{t(WRITING_PAGE.SHOW)}</Text>
                                <BaseSelect
                                    placement="top"
                                    width="80px"
                                    value={10}
                                    handleChange={() => { }}
                                    bg placeholder={t(WRITING_PAGE.STATUS)} options={[
                                        { label: 10, value: 10 },
                                        { label: 25, value: 25 },
                                        { label: 50, value: 50 },
                                    ]} />
                                <Text>{t(WRITING_PAGE.ON_THE_PAGE)}</Text>
                            </Flex>
                            <BasePagination
                                value={get(data, 'pagination.currentPageNumber', 0)}
                                total={get(data, 'pagination.totalPages', 0)}
                                handleChange={() => { }}
                            />
                        </Col>
                    </Row>
                </Content>
            </Col>
        </Row>
    )
}

export default Index