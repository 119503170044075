import { BaseButton, BaseInput, Text } from "components";
import { Col, Container, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useConnect from "../login/connect";
import { FormWrapper } from "../login/style";
import { useTranslation } from "react-i18next";
import { AUTH_CONSTANTS } from "../constants";

const StyledLoginForm = styled.div`
  width: 50%;
  margin: 0 auto;
  .row {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 800px) {
    width: 80%;
    margin: 0 auto;
    justify-items: center;
  }
  @media only screen and (min-width: 800px) {
    width: 80%;
    margin: 0 auto;
    justify-items: center;
  }
`;

const ChangeEmail = () => {
  const navigate = useNavigate();
  const { handleSubmit } = useConnect();
  const { t } = useTranslation();
  return (
    <StyledLoginForm>
      <Container fluid>
        <FormWrapper onSubmit={handleSubmit}>
          <Row align="center">
            <Col xs={12} style={{ textAlign: "center" }}>
              <Text
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontSize: "30px",
                  fontWeight: "500",
                }}
                xl
              >
                {t(AUTH_CONSTANTS.UPDATE_PASSWORD)}
              </Text>
            </Col>
            <Col
              style={{
                display: "flex",
                textAlign: "center",
                padding: "20px 0px",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  margin: "auto",
                  fontWeight: "400",
                  opacity: "0.5",
                }}
              >
                {t(AUTH_CONSTANTS.WE_SEND_MASSAGE)}
              </Text>
            </Col>

            <Col
              xs={12}
              style={{
                textAlign: "left",
                display: "flex",
                justifyContent: "spase-between",
                paddingTop: "30px",
                paddingLeft: "45px",
              }}
            >
              <Col
                xs={3}
                style={{
                  textAlign: "left",
                  marginTop: "8px",
                  padding: "0px",
                }}
              >
                <Text style={{ fontSize: "14px", fontWeight: "500" }}>
                  {t(AUTH_CONSTANTS.EMAIL)}
                </Text>
              </Col>
              <Col
                xs={10}
                style={{
                  maxWidth: "400px",
                  borderRadius: "8px",
                }}
              >
                <BaseInput
                  name={"password"}
                  type={"password"}
                  bg={"white"}
                  handleChange={function (): void {
                    throw new Error("Function not implemented.");
                  }}
                  style={{ backgroundColor: "white" }}
                />
              </Col>
            </Col>

            <Col
              xs={12}
              className={"mb-32"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                marginTop: "20px",
              }}
            >
              <BaseButton
                style={{
                  marginTop: "20px",
                  width: "123px",
                  height: "40px",
                  gap: "20px",
                  marginRight: "20px",
                }}
                type={"submit"}
                primary
                medium
                onClick={() => navigate("/reset-password")}
              >
                {t(AUTH_CONSTANTS.SUBMIT)}
              </BaseButton>
              <BaseButton
                style={{
                  marginTop: "20px",
                  width: "123px",
                  height: "40px",
                  backgroundColor: "grey",
                  color: "white",
                }}
                type={"submit"}
                medium
                onClick={() => navigate("/login")}
              >
                {t(AUTH_CONSTANTS.CANCEL)}
              </BaseButton>
            </Col>
          </Row>
        </FormWrapper>
      </Container>
    </StyledLoginForm>
  );
};

export default ChangeEmail;
