import styled from "styled-components";

export const StyledBaseBreadcrumb = styled.ul`
  /* margin-top: 10px; */
  margin-bottom: 10px;

  li {
    list-style-type: none;
    display: inline-flex;
    align-items: center;
    a {
      text-decoration: none;
    }
    .arrow {
      font-size: 36px;
      margin-left: 10px;
      padding-bottom: 3px;
      margin-right: 10px;
    }
    h2 {
      margin-bottom: 0;
      padding-left: 0;
    }
  }
`;
