import { Main } from "shared/constants/path";
import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { INNERLAYOUTS } from "layout/innerLayouts/constants";
const initialState: InitialState = {
  menus: [
    {
      name: INNERLAYOUTS.HOME,
      url: Main.HOME,
      id: 1,
      pages: [],
      allowed: true,
    },
    {
      name: INNERLAYOUTS.USERS,
      url: Main.USERS,
      id: 2,
      pages: [],
      allowed: true,
    },
    {
      name: INNERLAYOUTS.SPEAKING,
      url: Main.SPEAKING,
      id: 3,
      pages: [],
      allowed: true,
    },
    {
      name: INNERLAYOUTS.WRITING,
      url: Main.WRITING,
      id: 4,
      pages: [],
      allowed: true,
    },
    {
      name: INNERLAYOUTS.PUSH,
      url: Main.PUSH,
      id: 5,
      pages: [],
      allowed: true,
    },
  ],

  pages: {
    loading: false,
    data: [],
    error: "",
  },
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
});

export default menuSlice.reducer;
