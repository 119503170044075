import { customStyles, SelectWrapper,MenuButton } from "./style";
import { memo, Fragment } from "react";
import Select, { components } from "react-select";
import { get, isEqual } from "lodash";
import { ReactSVG } from "react-svg";
import arrowIcon from "assets/icons/arrow-down.svg";
import { Loader } from "@mantine/core";
import FlexBox from "../Flex";
// const [loadingNO, setLoadingNO] = useState<boolean>(true);
const DropdownIndicator = () => {
  return <ReactSVG className={"select-arrow"} src={arrowIcon as any} />;
};
const Menu = (props: any) => {
  return (
    <Fragment>
      <components.Menu {...props}>
        {props.selectProps.isCustomMenu && (
          <MenuButton green handleClick={props.selectProps.onPressButton}>
            {props.selectProps.menuButtonTitle}
          </MenuButton>
        )}
        <div>{props.children}</div>
      </components.Menu>
    </Fragment>
  );
};

const BaseSelect = ({
  options = [],
  defaultValue,
  width = "",
  value = "",
  height = "",
  isSearchable = true,
  onBlur,
  bg,
  isMulti = false,
  isDisabled = false,
  placeholder = "",
  isLoading = false,
  isClearable = false,
  handleChange = (val: any) => val,
  autoFocus = false,
  placeholderColor = "#1c1c1c",
  sm,
  error,
  isCustomMenu,
  menuButtonTitle,
  handleAddNewOption = () => {},
  handleValueLabel,
  readOnly,
  placement = "bottom",
  colorable = false,
  menuPosition = "absolute",
  ...rest
}: any) => {
  const handleChangeValue = (e: any) => {
    if (isMulti) {
      handleChange(e.map((item: any) => item.value));
    } else {
      handleChange(get(e, "value", null));
    }
    if (handleValueLabel) {
      handleValueLabel(e);
    }
  };
  const customNoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="link_size-14">Нет данных</span>
      </components.NoOptionsMessage>
    );
  };
  const Option = ({ colors, ...props }: any) => {
    return (
      <Fragment>
        <components.Option {...props}>
          {colorable ? (
            <FlexBox gap={"10px"}>
              <div
                style={customStyles({
                  sm,
                  isDisabled,
                  menuPosition,
                }).optionColor(props)}
              ></div>
              <span>{props.children}</span>
            </FlexBox>
          ) : (
            <div>{props.children}</div>
          )}
        </components.Option>
      </Fragment>
    );
  };
  const customLoadingIndicator = (props: any) => {
    return (
      <components.LoadingMessage {...props}>
        <Loader color="blue" />;
      </components.LoadingMessage>
    );
  };
  const loadLeft = () => <div></div>;
  const Control = ({ children, ...props }: any) => {
    return (
      <>
        {colorable ? (
          <components.Control {...props}>
            <span
              style={customStyles({
                sm,
                isDisabled,
                menuPosition,
              }).controlColor(get(props, "selectProps.value.color", "white"))}
            ></span>
            {children}
          </components.Control>
        ) : (
          <components.Control {...props}>{children}</components.Control>
        )}
      </>
    );
  };

  const _components: any = {
    DropdownIndicator: isDisabled ? null : DropdownIndicator,
    IndicatorSeparator: () => null,
    Menu: Menu,
    Option: Option,
    Control: Control,
    NoOptionsMessage: customNoOptionsMessage,
    LoadingIndicator: loadLeft,
    LoadingMessage: customLoadingIndicator,
  };

  const restProps = {
    onPressButton: handleAddNewOption,
    isCustomMenu,
    menuButtonTitle,
  };

  return (
    <SelectWrapper {...rest} width={width} bg={bg}>
      <Select
        defaultValue={defaultValue}
        menuPlacement={placement}
        autoFocus={autoFocus}
        key={`unique_key__${value}`}
        options={options}
        styles={customStyles({
          sm,
          isDisabled,
          menuPosition,
          bg,
          height,
          error,
          placeholderColor
        })}
        onChange={(value) => {
          handleChangeValue(value);
        }}
        value={
          isMulti && value && value.toString.length > 0
            ? options.filter((option: any) => isEqual(option.value, value))
            : options.find((item: any) => item.value === value)
        }
        isSearchable={isSearchable}
        placeholder={placeholder}
        onBlur={onBlur}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        components={_components}
        {...restProps}
      />
    </SelectWrapper>
  );
};

export default memo(BaseSelect);
