export const PROFILE_CONSTANTS = {
  TITLE: "Профиль",
  SETTINGS: "settings",
  MY_ACCAUNT: "my_accaunt",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  AUTH_GOOGLE:'auth_google',
  TITLE_QR:"title_qr",
  UPDATE: "update",
  CURRENT_PASSWORD: "Current_password",
  NEW_PASSWORD: "new_password",
  CONFIRM_PASSWORD: "confirm_password",
  ACCAUNT_CATEGORY: "Account_category",
  ADMIN: "admin",
  DEALER: "dealer",
  INFO: "info",
  UPDATE_PASSWORD: "updatePassword",
  REASONABLE_PRICE: "reasonably_priced",
  LOGOUT: "logout",
  ADD_BUTTON: "add",
  YOU_SHURE: "Are_you_sure_you_want_to_delete",
  UPLOAD_IMAGE: "Upload_image",
  CANCEL: "Cancel",
  IMAGE: "image",
};
