import styled, { css } from "styled-components";
import { StyledBaseButtonProps } from "./types";
import { Button } from "@mantine/core";



export const StyledBaseButton = styled(Button) <StyledBaseButtonProps>`
    border: none;
    background-color: #8a8d9d;
    &:hover{
        background-color: #8a8d9d;
    }
    outline: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 8px;
    color: ${({ color }:StyledBaseButtonProps) => color || "#fff"};
    padding: 8px 12px;
    cursor: pointer;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
        0px 1px 1px rgba(0, 0, 0, 0.08);
    font-size: ${({ fontSize }:StyledBaseButtonProps) => fontSize || "16px"};
    min-width: ${({ width }:StyledBaseButtonProps) => width || "100px"};
    height: 35px;
    .chevron {
        margin-left: 15px;
    }
    margin: ${({ margin }:StyledBaseButtonProps) => margin || "0px"};
    &[disabled] {
        cursor: not-allowed;
    }

    ${({ primary }:StyledBaseButtonProps) =>
        primary &&
        css`
            background-color: #0085ff;
            &:hover{
                background-color: #0085ff;
            }
        `};

    ${({ light_primary }:StyledBaseButtonProps) =>
        light_primary &&
        css`
            background-color: #407dc7;
            &:hover{
                background-color: #407dc7;
            }
        `};

    ${({ flower }:StyledBaseButtonProps) =>
        flower &&
        css`
            background-color: #b2b8fb;
            &:hover{
                background-color: #b2b8fb;
            }
        `};

    ${({ light_flower }:StyledBaseButtonProps) =>
        light_flower &&
        css`
            background-color: #7391f8;
            &:hover{
                background-color: #7391f8;
            }
        `};

    ${({ success }:StyledBaseButtonProps) =>
        success &&
        css`
            background-color: #53ac92;
            &:hover{
                background-color: #53ac92; 
            }
        `};

    ${({ tangerine }:StyledBaseButtonProps) =>
        tangerine &&
        css`
            background-color: #f79e1b;
            &:hover{
                background-color: #f79e1b;
            }
        `};

    ${({ info }:StyledBaseButtonProps) =>
        info &&
        css`
            background-color: #9247b5;
            &:hover{
                background-color: #9247b5;
            }
        `};

    ${({ danger }:StyledBaseButtonProps) =>
        danger &&
        css`
            background-color: rgba(216, 0, 39, 1);
            &:hover{
                background-color: rgba(216, 0, 39, 1);
            }
        `};

    ${({ light_danger }:StyledBaseButtonProps) =>
        light_danger &&
        css`
            background-color: #ec536a;
            &:hover{
                background-color: #ec536a;
            }
        `};

    ${({ green }:StyledBaseButtonProps) =>
        green &&
        css`
            background-color: #00a241;
            &:hover{
                background-color: #00a241;
            }
        `};

    ${({ gray, disabled }:StyledBaseButtonProps) =>
        (gray || disabled) &&
        css`
            background-color: #bbbbbb;
            &:hover{
                background-color: #bbbbbb;
            }
        `};

    ${({ bordered }:StyledBaseButtonProps) =>
        bordered &&
        css`
            border: solid 1px #0085ff;
            color: #0085ff;
            background-color: #ffffff;
            &:hover{
                border: solid 1px #0085ff;
            color: #0085ff;
            background-color: #ffffff;
            }
        `};

    ${({ medium }:StyledBaseButtonProps) =>
        medium &&
        css`
            font-weight: 500;
        `};

    ${({ outlined }:StyledBaseButtonProps) =>
        outlined &&
        css`
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
            border: 1px solid #e8e8e8;
            background-color: transparent;
            display: inline-flex;
            color: #1c1c1c;
            font-weight: 500;
        `};
`;