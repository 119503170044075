import styled from "styled-components";
import { css } from "styled-components";

export const WrapTranslate = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 40px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-left: 10px;

  .change_lang_text_p {
    display: flex;
    align-items: center;
    font-size: 20px;
    min-height: 100%;
  }
  .change_lang_icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: ease 1s;
    position: relative;
  }
  .change_lang_icon_wrapper {
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;
    height: auto;
  }
  .change_lang_icon_wrp {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
  }
`;

interface OptionLangListProps {
  dis: string;
  left: string;
}

export const OptionLangList = styled.div<OptionLangListProps>`
  overflow: hidden;
  text-align: center;
  padding: 0;
  ${({ dis }: { dis: string }) =>
    dis == "close"
      ? css`
          display: none;
        `
      : css`
          display: block;
        `};

  ${({ left }: { left: string }) =>
    left
      ? css`
          left: 0px;
        `
      : css`
          left: none;
        `};
  z-index: 1000;
  border-radius: 10px;
  transition: ease 0.5s;
  position: absolute;
  @media (max-width: 768px) {
    top: 85px;
  }
  top: 40px;
  min-height: 10vh;
  min-width: 100px;
  background-color: #ffffff;
`;

interface LanguageOptionProps {
  current: string;
}

export const LanguageOption = styled.div<LanguageOptionProps>`
  padding: 10px;
  /* width: max-content; */
  justify-content: space-between;
  font-size: medium;
  text-align: center;
  cursor: pointer;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: ease 0.5s;

  ${({ current }: { current: string }) =>
    current == "true" &&
    css`
      a {
        color: #0093ff;
        font-weight: 900;
      }
      background-color: #e5f3ff;
      color: #0093ff;
      span {
        color: #0093ff;
        font-weight: 900;
      }
    `}
  img {
    width: 29px;
    /* height: 15px;
    object-fit: cover; */
  }
  &:active {
    a {
      color: #0093ff;
      font-weight: 900;
    }
    background-color: #e5f3ff;
    color: #0093ff;
    span {
      color: #0093ff;
      font-weight: 900;
    }
  }
  &:hover {
    p {
      color: #0093ff;
      font-weight: 900;
    }
    color: #0093ff;
    span {
      color: #0093ff;
      font-weight: 900;
    }
  }
`;
