import { createSelector } from "@reduxjs/toolkit";
import { menus } from "app/selectors/menu";
import { get } from "lodash";

export const navBarMenus = createSelector([menus], (pages) =>
  get(pages, "menus", []).map(({ name, id, url, pages, allowed }: any) => ({
    name,
    url,
    id,
    pages,
    allowed,
  }))
);
export const sideBarMenus = createSelector(
  [menus, (navBar: string) => navBar],
  (pages, navBar) =>
    get(pages, "data", []).find((item: any) => item.id == navBar)
);
