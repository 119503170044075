import Text from 'components/Text';
import moment from 'moment';

function DateFormat({ date = '', isExl = false }: { date: string, isExl?: boolean }) {
    // Only proceed if a valid date is passed
    if (!date) return null; // Explicitly return null if date is falsy

    const currentDate = moment(date).format("YYYY.MM.DD HH:mm");

    if (isExl) {
        const exl = `${moment
            .utc(currentDate, "YYYY.MM.DD HH:mm")
            // .local()
            .format("YYYY.MM.DD")} ${moment
                .utc(currentDate, "YYYY.MM.DD HH:mm")
                // .local()
                .format("HH:mm")}`;
        return <>{exl}</>;
    }

    return (
        <>
            <Text>
                {moment
                    .utc(currentDate, "YYYY.MM.DD HH:mm")
                    // .local()
                    .format("YYYY.MM.DD")}
            </Text>
            <br />
            <Text style={{ opacity: 0.5 }} small xs>
                {moment
                    .utc(currentDate, "YYYY.MM.DD HH:mm")
                    // .local()
                    .format("HH:mm")}
            </Text>
        </>
    );
}

export default DateFormat;
