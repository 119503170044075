import styled from "styled-components";

const AuthNavContainer = styled.div`
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
  margin-top: 40px;
`;

const AuthNavWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 50px;
`;

export { AuthNavContainer, AuthNavWrapper };
