import request from "..";

class ProfileServices {
  static MyAccount = () => {
    return request.get(`/api/user/me`);
  };
  static ChangePassword = (params: any) => {
    return request.put(`/api/user/password`, { ...params });
  };
  static QrCode = () => {
    return request.get(`/api/2fa/generateQRCode`);
  }
  static Off2FA = () => {
    return request.delete(`/api/2fa`);
  }
  
  static On2FA = (params: any) => {
    return request.post(`/api/2fa`, { ...params });
  }
  static changeProfile = (params: any) => {
    return request.put(`/api/user/update`, { ...params });
  };
}

export default ProfileServices;
