import homeServices from "services/api/home";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { initialState } from "./type";

const InitialState: initialState = {
    lang: "en",
};

// export const fetchHomeData = createAsyncThunk(
//     "home/fetchHomeData",
//     async () => {
//         const request = await homeServices.dashboard();
//         if (get(request, "status", "") != 200) {
//             return get(request, "message", "");
//         }
//         const respond = await request.data;
//         return respond;
//     }
// )


const profileSlices = createSlice({
    name: "profile",
    initialState:InitialState,
    reducers: {
       handleChangeLanguage: (state, action) => {
           state.lang = get(action, "payload", "en");
       }
    },
    // extraReducers: (builder) => {
    //     builder.addCase(fetchHomeData.pending, (state) => {
    //         state.homeData = {
    //             data: {},
    //             loading: true,
    //             error: ''
    //         }
    //     });
    //     builder.addCase(fetchHomeData.fulfilled, (state, actions) => {
    //         state.homeData = {
    //             data: get(actions, "payload.data", {}),
    //             loading: false,
    //             error: ''
    //         }
    //     });
    //     builder.addCase(fetchHomeData.rejected, (state, actions) => {
    //         state.homeData = {
    //             data: {},
    //             loading: false,
    //             error: get(actions, "error.message", '')
    //         }
    //     });

    // },
});

export const { handleChangeLanguage } = profileSlices.actions;
export default profileSlices.reducer;
