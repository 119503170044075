import styled, { css } from "styled-components";

interface StyledContentProps {
  height?: string | any;
  rounded?: boolean | undefined | null | string;
  maxheight?: string | any;
}
const StyledContent = styled.section<StyledContentProps>`
  background: #ffffff;
  position: relative;
  /* min-height: 80vh; */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 0px 0px;
  padding: 10px 20px 10px 20px;
  margin: 0 auto;
  min-height: ${({ height }: { height?: string | any }) => height || "78vh"};
  max-height: ${({ maxheight }: { maxheight?: string | any }) =>
    maxheight || "max-content"};

  ${({ rounded }: { rounded?:any}) =>
    rounded=='true' &&
    css`
      border-radius: 8px;
    `};
  border-radius: 8px;
  /* @media (min-width: 1600px) {
    margin-left: 3rem;
    margin-right: 3rem;
  } */
`;
//max-content
export { StyledContent };
