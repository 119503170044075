import { Flex, Text, Title } from "components";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { LineIconWrp, StyledBaseLineChart, StyledCustomTooltip } from "./style";
import { useState } from "react";

const CustomizedAxisTick = (props: any) => {
  const { x, y, stroke, payload, hovered, activeLabel } = props;


  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        dx={12}
        textAnchor="end"
        fill={payload.value === activeLabel ? "#0084ff" : "rgba(150, 150, 150, 1)"}

      >
        {payload &&
          payload.value &&
          moment(get(payload, "value", new Date())).local()
            .format("MMMM")
            .substring(0, 3)}
      </text>
      <text
        x={0}
        y={0}
        dy={40}
        dx={17}
        textAnchor="end"
        fill="#000"
      >
        {payload.index === 0
          ? payload?.value &&
          moment(get(payload, "value", new Date())).local().format("YYYY")
          : payload?.value &&
            moment(payload.value).local().format("MMMM").substring(0, 3) ===
            "Jan"
            ? payload.value && moment(payload.value).local().format("YYYY")
            : ""}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, setActiveLabel }: any) => {
  setActiveLabel(null);
  if (active && payload && payload.length && label) {
    setActiveLabel(get(payload, '[0].payload.yyyymmName', ''));
    return (
      <StyledCustomTooltip  >
        <Title>
          {payload[0].payload.yyyymm.fullName + " " + payload[0].payload.year}
        </Title>
        <ul>
          {payload.map((elm: any, index: any) => (
            <li key={index + 1}>
              <Flex style={{ justifyContent: 'space-between' }}>
                <LineIconWrp color={get(elm, "color", "")}>
                  <div className="graph-cnty-ln"></div>
                  <div className="graph-cnty-crc"></div>
                </LineIconWrp>
                <Text xs gray className="label ml-8">
                  <NumericFormat
                    displayType={"text"}
                    thousandSeparator={","}
                    value={get(elm, `payload.${get(elm, "name", "")}`, '')}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={get(elm, "name", "") + " "}
                    renderText={(value: any) => Number(value) !== 0 ? value : value} />
                </Text>
              </Flex>
            </li>

          ))}
        </ul>
      </StyledCustomTooltip>
    );
  }

  return <StyledCustomTooltip></StyledCustomTooltip>;
};
const BaseLineChart = ({
  data = [],
  lines = [],
  type = "monotone",
  date = "month",
  ...props
}: any) => {
  const [activeLabel, setActiveLabel] = useState<any>();
  return (
    <StyledBaseLineChart {...props}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 0,
            right: 36,
            left: 0,
            bottom: 30,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            stroke="#e0e0e0"
            vertical={false}
            horizontal={true}
          />
          <XAxis

            dataKey="yyyymmName"
            tick={<CustomizedAxisTick activeLabel={activeLabel} />} />
          <YAxis
            tickSize={0}
            axisLine={false}
            tickMargin={1}
            width={100}
            tickFormatter={(value: any) => value ? ` ${value.toLocaleString()}` : value} />
          <Tooltip
            cursor={{
              stroke: '#0084ff',
              strokeDasharray: '3 3',
            }}
            content={<CustomTooltip setActiveLabel={setActiveLabel} />} />
          <Legend
            layout="horizontal"
            wrapperStyle={{}}
            verticalAlign="bottom"
            align="left"
            height={36}
          />
          {!isEmpty(lines) &&
            lines.map((line: any, index: any) => (
              <Line
                type="linear"
                key={index + 1}
                dataKey={get(line, "dataKey", "")}
                strokeWidth={2}
                stroke={get(line, "stroke", "")}

                dot={{
                  stroke: get(line, "stroke", ""),
                  color: get(line, "stroke", ""),
                  r: 4,
                  fill: get(line, "stroke", ""),
                }}
                activeDot={{
                  r: 8,
                  fill: "#ffff",
                  stroke: get(line, "stroke", ""),
                }}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </StyledBaseLineChart>
  );
};

export default BaseLineChart;
