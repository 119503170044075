import { AuthNavContainer, AuthNavWrapper } from "./style";
import { Text } from "@mantine/core";
import FlexBox from "../Flex";
import { useTranslation } from "react-i18next";
import { Row } from "react-grid-system";
import { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";

const AuthNav = () => {
  const { t } = useTranslation();
  return (
    <AuthNavContainer
      style={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <AuthNavWrapper style={{ width: "80%" }}>
        {/* <FlexBox justify={"space-between"} className={"w-100 align-center"}> */}
        <div
          style={{
            width: "100%",
            padding: "20px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              display: "inline-block",
              alignItems: "center",
              margin: "20px 0 0 0px",
            }}
            size={"xl"}
            c={"dark"}
          >
            Sayra App
          </Text>
          <Text
            style={{
              margin: "20px 0 0 0px",
            }}
            size="xl"
          >
            Admin Panel
          </Text>
        </div>
        {/* </FlexBox> */}
      </AuthNavWrapper>
    </AuthNavContainer>
  );
};

export default AuthNav;
