import { Title, BaseModal, FormInput, BaseButton } from "components";
import useConnect from "./connect";
import { Col, Row } from "react-grid-system";
import { useEffect } from "react";
// import { BUTTONS } from "global/constants";
// import { FORM_VALUE, TITLES } from "modules/profile/constants";
import QRCode from "react-qr-code";
import { get, isEmpty } from "lodash";
import { ClipLoader } from "react-spinners";
import { PROFILE_CONSTANTS } from "modules/profile/constants";
import { useTranslation } from "react-i18next";
const ModalDetails = () => {
  const {
    values,
    handleSubmit,
    setOpen,
    setFieldValue,
    qrCodeFun,
    qrCode,
  } = useConnect();
  useEffect(() => {
    qrCodeFun();
  }, []);
  const { t } = useTranslation()
  return (
    <form onSubmit={handleSubmit} className="text-center py-16">
      <Title sm className={"px-16"}>
        {t(PROFILE_CONSTANTS.TITLE_QR)}
      </Title>
      <div style={{ minHeight: "300px", minWidth: "300px" }} className="display_flex_center">
        {!isEmpty(qrCode) ? (
          <QRCode value={qrCode} className="my-32" />
        ) : (
          <ClipLoader />
        )}
      </div>
      <br />
      <Row justify="center" align="center">
        <FormInput
          TACeonter={true}
          // border={"1px solid #b9b5b5"}
          // displayType="input"
          placeholder="Enter the code "
          width={"150px"}
          bg
          // format={"######"}
          // mask="_"
          name={"code"}
          value={get(values, "code", "")}
          handleOnChange={(e: string) => {
            setFieldValue("code", String(get(e, 'target.value', '')));

          }}
        />
        {/* <BaseButton
          width={"70px"}
          className="ml-4"
          type='submit'
          handleClick={() => handleSubmit}
          disabled={String(get(values, "code", "")).length < 6}
        >
          &#10148;
        </BaseButton> */}
      </Row>


    </form>
  );
};
const EmailVerify = () => {
  const { handleSubmit, isConnact, open, setOpen, delete2Fa } = useConnect();
  const { t } = useTranslation()
  return (
    <form onSubmit={handleSubmit} className={"ml-128"}>
      <Row className="mt-32" align="center">
        <BaseModal opened={open} onClick={() => setOpen} close={() => setOpen(false)}>
          <ModalDetails />
        </BaseModal>
        <Col xs={3}>
          <Title>{t(PROFILE_CONSTANTS.AUTH_GOOGLE)}</Title>
        </Col>
        <Col xs={9}>
          {!isConnact ? (
            <BaseButton primary onClick={() => setOpen(true)}>Connect</BaseButton>
          ) : (
            <BaseButton tangerine handleClick={() => delete2Fa()}>
              Disconnect
            </BaseButton>
          )}
        </Col>
      </Row>
    </form>
  );
};

export default EmailVerify;
