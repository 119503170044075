import { memo } from "react";
import { TableWrapper } from "./style";
import { Props } from "./type";

const BaseTable: React.FC<Props> = ({
  children,
  tableHeader = [],
  email,
  overflow,
  hasUpdate = true,
  hasDelete = true,
  ...props
}) => {
  let sliceEnd = tableHeader.length;
  if (!hasUpdate) {
    sliceEnd -= 2; // Remove last 2 elements
  } else if (!hasDelete) {
    sliceEnd -= 1; // Remove last element
  }

  return (
    <div>
      <TableWrapper {...props}>
        <thead>
          <tr>
            {tableHeader &&
              tableHeader
                .map((item: string, index: number) => {
                  return <th style={{ textAlign: email&&index==1?"left":"center" }} key={`${item}-${index + 1}`}>{item}</th>;
                })
                .slice(0, sliceEnd)}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </TableWrapper>
    </div>
  );
};

export default memo(BaseTable);
