import styled from "styled-components";
interface propsType {
  h?: string;
}
export const LoadingStyle = styled.div<propsType>`
  width: 100%;
  height: ${({ h }: { h?: string }) => h || "60vh"};
  display: flex;
  justify-content: center;
  align-items: center;
`;
