import { createGlobalStyle } from "styled-components";
export default createGlobalStyle`
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.z-10{
  z-index: 100;
}

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
}

.custom-hr {
  margin: 0;
}

.label{
  font-weight: 500;
    font-size: 12px;
    margin-left: 10px;
}

body {
  font-weight: 400;
  font-size: 16px;
  color: #585757;
  background: #F7F7F7;
  line-height: 1.25;
  font-family: 'Inter', sans-serif;
}

.table_scroll {
table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  overflow-y: auto ;
}
thead th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}
}


.scroll_style {
  overflow-y: auto;
}

.scroll_style::-webkit-scrollbar {
  width: 0.5em;
  height: 8px;
  border-radius: 1em;
}

.scroll_style::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll_style::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1em;
}

.scroll_style::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.card{
  width: 100%;
  border: 1px solid black;
  border-radius: 20px;
  height: 100px;
}

.pagination_container{
    display: flex;
    justify-content: flex-end;
    list-style: none;
    li{
      display: flex;
        margin-right: 8px;
        cursor: pointer;
        &:last-child{
            margin-right: 0;
        }
        a{
            text-decoration: none;
            padding: 8px 12px;
            background-color: #fff;
            border: 1px solid #E8E8E8;
            color: #1C1C1C;
            border-radius: 8px;
        }
        &.pagination_active{
          a{
              background-color: #0085FF;
              border-color: #0085FF;
              color: #fff;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08);
          }
        }
        &.pagination_disabled{
            a {
                color: #969696;
                cursor: not-allowed;
            }
        }
    }
}


.p-30{
  padding: 5px 15px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-12 {

  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.mr-128 {
  margin-right: 128px !important;
}

.mr-256 {
  margin-right: 256px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.ml-64 {
  margin-left: 64px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.mb-256 {
  margin-bottom: 256px !important;
}

.mb-128 {
  margin-bottom: 128px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-4 {
  margin-top: 4px !important;
}


.mt-128 {
  margin-top: 128px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-24 {
  margin-top: 24px !important;
}
.mt-16{
  margin-top: 16px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-32 {
  margin-left: 32px !important;
}
.ml-20v {
margin-left: 20vh !important;
}



.mr-8 {
  margin-right: 8px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.p-0 {
padding: 0 !important;
}

.p-4 {
  padding: 4px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-16 {
  padding: 16px !important;
}
.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.px-24{
  padding-left: 24px!important;
  padding-right: 24px!important;
}

.p-32 {
  padding: 32px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pr-64 {
padding-right: 64px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 16px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.mx-16 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}
.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}


.display_flex_center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.link_color {
  color: #4582F0;
  font-style: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}
.bold_text {
font-family: 'Inter';
font-style: normal;
font-weight: 500 !important;
font-size: 15px !important;
line-height: 24px !important;
text-align: center;
color: #1E1E1E !important;
}
.unstyle_anchor_tag {
    text-decoration: none;
    color: inherit;
}

.link_color_black {
  color: #585757;
  font-weight: 400;
  text-decoration: underline;

  &:hover  {
    color: #0085FF;
  }
}

.link_size-12 {
  font-size: 12px;
}

.link_size-16 {
  font-size: 16px;
}


.p-16 {
  padding: 16px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-38{
  padding-bottom: 38px !important;
}

.p-50 {
  padding: 50px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}
.px-16{
  padding-right: 16px !important;
  padding-left: 16px !important;
}
.mr-24{
  margin-right: 24px !important;
}

.mr-32{
  margin-right: 32px !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-end {
  text-align: end !important;
}

.ml-128 {
  margin-left: 128px !important;
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
};

.px-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}


.border {
  border-top: 0.5px solid #E8E8E8 !important;
  border-bottom: 0.5px solid #E8E8E8 !important; 
  border-right:0px solid  !important;
  border-left:0px solid  !important;

}
.minWidth-tdPhone {
  min-width: 180px;
}

.visibility-hidden {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.list-style-type {
  list-style-type: none;
}

.inline-block {
  display: inline-block !important;
}

.react-datepicker{
  display: flex;
}
.responsive_overflow{
  overflow: auto;
  width: 100%;
  ::-webkit-scrollbar {
        cursor: pointer;
        width: 100%;
        height: 8px;
        margin: 0.1em;
        border-radius: 50px;
        padding-right: 60px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.1);
        outline: 1px solid rgba(0, 0, 0, 0.1);
    }
}
.base-input{
  box-shadow: 0px 1px 1px rgb(0 0 0 / 6%);
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  padding: 8px 12px;
  outline: none;
  color: #1C1C1C;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
}
.reset__link {
  color: #969696;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s ease;

  &:hover {
    text-decoration: underline;
    color: #0085FF;
  }
}
.d-none{
  display: none;
}
.img-fluid{
  max-width: 100%;
  height: auto;
}
.pg-detail hr{
  border-bottom: 0.5px solid #E8E8E8 !important;
}

.editorClassName {
  width: 100%;
  height: 614px;
  border: 1px solid #E8E8E8;
}

.action_button{
  width:100%;
  display: flex;
  font-size: 16px;
  font-weight:500;
  gap:5px;
  padding:6px;  
  text-align:center;
  cursor: pointer;
}

hr {
  border-bottom: 0.5px solid #E8E8E8 !important;
  border-top: none;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }
  .print{
    width: 1800px !important;
    background-color:red;
    max-width: unset !important;
    flex: 0 0 100% !important;
  }
  .pg-detail{
    box-shadow: unset;
  }
}

.primary {
  color: #2050F5
}

.danger {
  color: #F96565;
}

.temps_design{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  background-image: linear-gradient(135deg, #f34079 40%, #fc894d);
  border: 0;
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  span {
  transition: all 200ms;
}

  &:hover span {
  transform: scale(1.3);
  opacity: 0.9;
}
}

.border {
  border: 1px solid black;
}

.light-border {
  border: 1px solid #e8e8e8;
}

.pagination_position {
  position: absolute;
  bottom: 16px;
  width: 100%;
}

.mt-12 {
  margin-top: 12px !important;
}

a {
  text-decoration: none;
}

.form-input-date_picker {
  width: 100% !important;
  max-width: 350px !important;
}


.svg_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding:5px;
    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.react-tabs__tab--selected {
  color: rgba(0, 133, 255, 1);
  border-bottom: 1px solid rgba(0, 133, 255, 1);
}

.react-tabs__tab-list {
  display: flex;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
}

.react-tabs__tab {
  padding: 8px 60px;
  list-style: none;
  cursor: pointer;
}
.react-tabs__tab:first-child {
  margin-left: 32px;
}

.self-align-center{
  align-self: center;
}

`;
