import {
  useEffect,
  useState,
  ChangeEvent,
  forwardRef,
  LegacyRef,
  Ref,
} from "react";
import { BaseInputProps } from "./types";
import { StyledBaseInput, StyleLineLoading } from "./style";

const BaseInput = forwardRef<HTMLInputElement, any>(
  (
    {
      clear = "",
      placeholder = "",
      bg,
      rightSection = "",
      error,
      disabled = false,
      name,
      height,
      loading = false,
      type = "text",
      handleInput = () => {},
      onKeyDown = () => {},
      ...props
    }: BaseInputProps,
    ref?: Ref<HTMLInputElement | undefined> | undefined
  ) => {
    const [val, setVal] = useState<any>(clear);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      !disabled && setVal(e.target.value);
      !disabled && handleInput(e.target.value);
    };

    useEffect(() => {
      if (clear.length === 0) {
        setVal(clear);
      }
    }, [clear]);
    return (
      // <div style={{ position: "relative",width:'100%' }}>
      <StyledBaseInput
        value={val}
        name={name}
        radius={"8px"}
        error={error}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        disabled={disabled}
        height={height}
        bgC={bg ? true : false}
        onChange={handleChange}
        type={type}
        ref={ref}
        rightSection={rightSection}
        {...props}
      />

      //   {loading && <StyleLineLoading >
      //     <Loader size="sm"/>
      //   </StyleLineLoading>}
      // </div>
    );
  }
);

export default BaseInput;
