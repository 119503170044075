import { useAppDispatch, useAppSelector } from "app/hook";
import { profileInfo } from "app/slices/authSlices/authSlices";
import { authData } from "app/states/auth";
import { useFormik } from "formik";
import { get } from "lodash";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { GLOBAL_CONSTANTS } from "modules/globalConstants";
import { useTranslation } from "react-i18next";
import ProfileServices from "services/api/profile";

const verfySchema = object({
  firstName: string().trim().required("full name is required"),
  lastName: string().trim().required("address is required"),
});

const useConnectInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuthData = useAppSelector(authData);
  const { t } = useTranslation();
  const profile = get(userAuthData, "userData.data", {});

  const onSubmit = (values: any) => {
    ProfileServices.changeProfile(values)
      .then((res: any) => {
        setSubmitting(false);
        if (res.status == 200) {
          toast.success(t(GLOBAL_CONSTANTS.UPDATED));
          dispatch(profileInfo());
        }
      })
      .catch(() => setSubmitting(false));
  };

  const {
    handleSubmit,
    values,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    errors,
    touched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: get(profile, "firstName", ""),
      lastName: get(profile, "lastName", ""),
    },
    onSubmit,
    validationSchema: verfySchema,
  });

 

  return {
    profile,
    // region,
    errors,
    touched,
    // data,
    // districts,
    navigate,
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  };
};

export default useConnectInfo;
