import {
  BaseButton,
  BaseInput,
  BasePagination,
  BaseSelect,
  BaseTable,
  Content,
  DateFormat,
  Flex,
  Loading,
  Text,
  Title,
  WindowHeight
} from "components"
import { Col, Row } from "react-grid-system"
import { useTranslation } from "react-i18next"
import { USERES_PAGE } from "./constants";
import useConnect from "./connect";
import { get, isEmpty } from "lodash";
import moment from "moment";
import Nodata from "components/Nodata/Nodata";
import dots from 'assets/icons/more_horiz.svg'
import { ReactSVG } from "react-svg";
import BaseBreadcrumb from "components/Bredcrump";
import { dateFormat } from "shared/helpers";
import { Skeleton } from "@mantine/core";

function Index() {
  const { t } = useTranslation();
  const { data, filter, fetchFilterUsers, handleFilter, handleReset, totals } = useConnect();

  return (
    <Row>
      <Col className="mt-8" xs={12}>
        <BaseBreadcrumb items={[
          { url: '', name: t(USERES_PAGE.USERS), id: 1 }
        ]} />
      </Col>
      <Col xs={12}>
        <Content>

          <Col xs={12}>
            <Row style={{ paddingBottom: '10px' }}>
              <Col xs={2}>

                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                  <Text
                    large medium>
                    <span style={{ color: '#969696', fontSize: '16px' }}>
                      {t(USERES_PAGE.TOTAL_USERS)}: </span>
                    {Number(get(totals, 'data.totalStudents', 0)).toLocaleString()}</Text>}
              </Col>
              <Col xs={1.5}>
                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                  <Text large medium>
                    <span
                      style={{ color: '#969696', fontSize: '16px' }}
                    >{t(USERES_PAGE.ACTIVE)}:
                    </span>{Number(get(totals, 'data.active', 0)).toLocaleString()}</Text>}
              </Col>
              <Col xs={1.5}>
                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                  <Text large medium>
                    <span style={{ color: '#969696', fontSize: '16px' }}>
                      {t(USERES_PAGE.INACTIVE)}:
                    </span>{Number(get(totals, 'data.inactive', 0)).toLocaleString()}</Text>}
              </Col>
              <Col xs={1.5}>
                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                  <Text large medium><span style={{ color: '#969696', fontSize: '16px' }}>{t(USERES_PAGE.BLOCKED)}: </span>{Number(get(totals, 'data.blocked', 0)).toLocaleString()}</Text>}
              </Col>
              <Col xs={1.5}>
                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                  <Text large medium><span style={{ color: '#969696', fontSize: '16px' }}>{t(USERES_PAGE.DELETED)}: </span>{Number(get(totals, 'data.deleted', 0)).toLocaleString()}</Text>}
              </Col>
              <Col xs={4}>
                {get(totals, 'loading', false) ? <Skeleton height={20} /> :
                  <Text large medium><span style={{ color: '#969696', fontSize: '16px' }}>{t(USERES_PAGE.LAST_SIGNUPS_IN_24_HOURS)}: </span>{Number(get(totals, 'data.newStudentsDaily', 0)).toLocaleString()}</Text>}
              </Col>
            </Row>
            <hr style={{ margin: '0 -35px' }} />
          </Col>
          <Row justify="between" style={{ padding: '10px 30px' }}>
            <Flex gap="10px">
              <BaseInput
                handleInput={(e: string) => handleFilter({ name: "search", value: e })}
                value={filter.search}
                width="300px"
                bg
                placeholder={t(USERES_PAGE.SEARCH_INPUT)} />

              <BaseSelect
                width="100px"
                bg
                handleChange={(e: string) => handleFilter({ name: "status", value: e })}
                value={filter.status}
                placeholder={t(USERES_PAGE.STATUS)}
                options={[
                  { label: t(USERES_PAGE.ACTIVE), value: "ACTIVE" },
                  { label: t(USERES_PAGE.INACTIVE), value: "INACTIVE" },
                ]} />
            </Flex>
            <Flex gap="10px">
              <BaseButton onClick={fetchFilterUsers} primary>{t(USERES_PAGE.APPLY)}</BaseButton>
              <BaseButton onClick={handleReset} danger>{t(USERES_PAGE.RESET)}</BaseButton>
            </Flex>
          </Row>
          <hr style={{ margin: '0 -20px' }} />
          <Row>
            <Col xs={12}>
              <WindowHeight manual="19.5">
                {get(data, "loading", false) ? (
                  <Loading />
                ) : (
                  <BaseTable
                    className="table_scroll"
                    email={true}
                    // hasDelete={ability.can(Permissions.DELETE, PAGES.SPARES)}
                    // hasUpdate={ability.can(Permissions.UPDATE, PAGES.SPARES)}
                    tableHeader={[
                      "ID",
                      t(USERES_PAGE.EMAIL),
                      t(USERES_PAGE.SESSIONS_FREE),
                      t(USERES_PAGE.SESSION_PAID),
                      t(USERES_PAGE.DEVICE_TYPE),
                      t(USERES_PAGE.SIGN_UP_DATE),
                      t(USERES_PAGE.LAST_ACTIVE),
                      t(USERES_PAGE.STATUS),
                      t(USERES_PAGE.ACTIONS),
                    ]}
                  >
                    {!isEmpty(get(data, "data", [])) ? (
                      get(data, "data", []).map((item: any, index: number) => (
                        <tr
                          className="pointer"
                          key={item.id}

                        >
                          <td>
                            {get(item, 'id', '')}
                          </td>
                          <td style={{
                            width: '400px',
                            textAlign: 'left',
                          }}>
                            {get(item, "email", null)
                              ? get(item, "email", "-")
                              : "-"}
                          </td>
                          <td>{get(item, "free_session", "")}</td>
                          <td>{get(item, "paid_session", "")}</td>
                          <td>{get(item, 'device_type', null) ? get(item, 'device_type', '-') : '-'}</td>
                          <td>
                            <DateFormat date={get(item, "created_at", "")} />
                          </td>
                          <td>{!get(item, "last_active", null) ? '-' :
                            <DateFormat
                              date={(get(item, "last_active", ''))}
                            />
                          }
                          </td>
                          <td style={{ color: get(item, "status", "INACTIVE") === "ACTIVE" ? "green" : "#F79E1B" }}>{t(get(item, "status", "INACTIVE"))}</td>
                          <td>
                            <ReactSVG src={dots as any} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <Nodata colLength={8} />
                    )}
                  </BaseTable>
                )}
              </WindowHeight>
            </Col>
            <Col xs={12} style={{ marginTop: "10px", alignItems: "center", justifyContent: 'space-between', display: "flex" }}>
              <Flex gap="10px">
                <Text>{t(USERES_PAGE.SHOW)}</Text>
                <BaseSelect
                  placement="top"
                  width="80px"
                  value={filter.size}
                  handleChange={(val: number) => handleFilter({ name: 'size', value: val })}
                  bg placeholder={t(USERES_PAGE.STATUS)} options={[
                    { label: 10, value: 10 },
                    { label: 25, value: 25 },
                    { label: 50, value: 50 },
                  ]} />
                <Text>{t(USERES_PAGE.ON_THE_PAGE)}</Text>
              </Flex>
              <BasePagination
                value={get(data, 'pagination.currentPageNumber', 0)}
                total={get(data, 'pagination.totalPages', 0)}
                handleChange={(val: number) => handleFilter({ name: 'page', value: get(val, 'selected', 0) })} />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
}

export default Index