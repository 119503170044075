import { Auth } from "shared/constants/path";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import { Loading } from "components";
const ReloadPage: React.FC = () => {
  const navigate = useNavigate();
  let reloadCount = Number(sessionStorage.getItem("reloadCount")) || 0;
  useEffect(() => {
    if (reloadCount < 2) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
      const timer = setTimeout(() => {
        navigate(Auth.LOGIN);
      }, 500); 
      return () => clearTimeout(timer);
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "white",
        fontSize: "1.5rem",
      }}
    >
      <div
        style={{
          display: "block",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Loading h="10vh"  />
        Auth checking...
      </div>
    </div>
  );
};

export default ReloadPage;
