
export const SESSIONS_PAGE = {
    ACTIONS: "actions",
    ALL: "All",
    SESSIONS: "Sessions",
    TOTAL: "total",
    FREE: "free",
    PAID: "paid",
    FREE_FAILRD: "free_failed",
    PAID_FAILRD: "paid_failed",
    FREE_IN_24_HOURS: "free_in_24_hours",
    PAID_IN_24_HOURS: "paid_in_24_hours",
    SESSION_TYPE: "session_type",
    AMOUNT: "amount",
    PAYMENT_TYPE:'payment_type',
    USER: "user",
    DATE: 'date',
    TOTAL_USERS: 'total_users',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    APPLY: 'apply',
    RESET: 'reset',
    SEARCH_INPUT: 'search_input',
    STATUS: 'status',
    EMAIL: "email",
    SHOW: "show",
    ON_THE_PAGE: "on_the_page"
}