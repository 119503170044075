import styled from "styled-components";
import { Input } from "@mantine/core";
import { ChangeEvent, forwardRef, KeyboardEventHandler } from "react";

// Define the props interface for styled-components
interface StyledBaseInputProps {
  bgC?: boolean;
  width?: string;
  margin?: string;
  disabled?: boolean;
  height?: string;
  error?: string | boolean|undefined;
  type?: string | undefined;
  loading?: boolean | undefined;
  name?:string|undefined;
  radius?:string|undefined;
  ref?:any;
  placeholder?:string|undefined;
  onKeyDown?:KeyboardEventHandler<HTMLInputElement>;
  onChange?:((e:ChangeEvent<HTMLInputElement>)=>void)|undefined;
  rightSection?:any;
}
// const StyledInputWrapper = forwardRef<HTMLInputElement, StyledBaseInputProps>((props, ref) => (
//   <Input
//     {...props}
//     ref={ref}  // Make sure ref is passed to the Input component
//   />
// ));

// Styled component for Input using styled-components
export const StyledBaseInput = styled(Input) <StyledBaseInputProps>`
  input {
    /* position: absolute; */
    background: ${({ bgC }: { bgC?: boolean }) =>
    bgC ? "#ffffff" : "#f2f2f2"};
    width:  ${({ width }:StyledBaseInputProps) => width || "100%"};
    margin: ${({ margin }:StyledBaseInputProps) => margin || "0px"};
    box-shadow: 0px 1px 1px 0px #0000000f;
    color: ${({ disabled }:StyledBaseInputProps) => (disabled ? "#b6bbc0" : "#1c1c1c")};
    border-radius: 8px;
    border: ${({ error }:StyledBaseInputProps) =>
    error ? "1px solid #f96565" : "1px solid #E8E8E8"};
    outline: none;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    min-height: ${({ height }: StyledBaseInputProps) => height || " 36px"};
    width: 100%;
    &::placeholder {
      color: #979797 !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      /* font-family: "Inter", sans-serif !important; */

      opacity: 1;
    }
    &:disabled {
      background-color: #f2f2f2 !important;
      border: 1px solid #e8e8e8 !important;
      color: #1c1c1c;
      cursor: not-allowed;
      opacity: 1;
    }
  }
`;

export const StyleLineLoading = styled.div`
  position: absolute;
  right: 10px;
  bottom: 5px;
  /* width: 100%; */
  z-index: 4;
  .loader-line {
    width: 100%;
    height: 3px;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 50%;
  }

  .loader-line:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width:10%;
    background-color: #4d7ce0;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }

  @keyframes lineAnim {
    0% {
      left: -40%;
    }
    50% {
      left: 20%;
      width: 80%;
    }
    100% {
      left: 100%;
      width: 100%;
    }
  }
`;
