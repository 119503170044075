import { Auth, Main, PROFILE } from "shared/constants/path";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import {
  AuthLayout,
  MainLayout,
  ProfileLayout,
  PushLayout,
  SpeakingLayout,
  UsersLayout,
  WritingLayout
} from "layout";
import {
  ChangeEmailPage,
  ForgetPasswordPage,
  HomePage,
  LoginPage,
  MyAccount,
  Users,
  ReloadPage,
  Push,
  NotificationDetails,
  Writing,
  Speaking,
} from "modules";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* main */}
      <Route path={Main.HOME} element={<MainLayout />}>
        {/* home */}
        <Route index element={<HomePage />} />
        {/* Profile */}
        <Route path={Main.PROFILE} element={<ProfileLayout />}>
          <Route path={PROFILE.MY_PROFILE} element={<MyAccount />} />
        </Route>
        {/* users */}
        <Route path={Main.USERS} element={<UsersLayout />}>
          <Route path={Main.USERS} element={<Users />} />
        </Route>
        {/* speaking */}
        <Route path={Main.SPEAKING} element={<SpeakingLayout />}>
          <Route path={Main.SPEAKING} element={<Speaking />} />
        </Route>
        {/* writing */}
        <Route path={Main.WRITING} element={<WritingLayout />}>
          <Route path={Main.WRITING} element={<Writing />} />
        </Route>
        {/* push notfcations */}
        <Route path={Main.PUSH} element={<PushLayout />} >
          <Route path={Main.PUSH} element={<Push />} />
          <Route path={Main.PUSH + '/:pushId'} element={<NotificationDetails />} />
        </Route>
      </Route>
      {/* auth */}
      <Route element={<AuthLayout />}>
        <Route path={Auth.LOGIN} element={<LoginPage />} />
        <Route path={Auth.RESET_PASSWORD} element={<ForgetPasswordPage />} />
        <Route path={Auth.CHANGE_EMAIL} element={<ChangeEmailPage />} />
      </Route>

      <Route path={Auth.RELOAD} element={<ReloadPage />} />
      <Route
        path="*"
        element={
          <div>
            <p>No Access</p>
          </div>
        }
      />
    </Route>
  )
);
// const sheet = new ServerStyleSheet();
// const html = renderToString(sheet.collectStyles(<RouterProvider router={router} />));
const MainRouter = () => {
  return <RouterProvider router={router} />;
};

export default MainRouter;
