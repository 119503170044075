import { createSelector } from "@reduxjs/toolkit";
import { selectGetAllSpeaking, selectSpeakingFilter, selectTotalSpeaking } from "app/selectors/speaking";

export const getAllSpeaking = createSelector(
  [selectGetAllSpeaking],
  (data) =>data);
export const SpeakingFilter = createSelector(
  [selectSpeakingFilter],
  (data) =>data
);
export const SpeakingTotals = createSelector(
  [selectTotalSpeaking],
  (data) =>data
)