import { FormInput, Text } from "components";
import { ChangeEvent } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { FormWrapper, StyledLoginForm } from "./style";
import useConnect from "./connect";
import { get } from "lodash";
import { Button } from "@mantine/core";
import { AUTH_CONSTANTS } from "../constants";
import { useTranslation } from "react-i18next";

const Login = () => {
  const {
    handleSubmit,
    is2FA,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    values } = useConnect();
  const { t } = useTranslation();
  return (
    <Container className="mt-32" style={{ width: "90%", display: "flex", justifyContent: "center", alignItems: 'center' }} fluid>
      <FormWrapper onSubmit={handleSubmit}>
        <Row className="mb-16 mt-32">
          <Col xs={2}></Col>
          <Col
            xs={2} >
            <div style={{ textAlign: "center", marginTop: "8px" }}>
              <Text
                style={{
                  textAlign: "left",
                  width: "150px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: get(touched, "email", false) && get(errors, "email", "") ? "#F71B1B" : ""
                }}
              >
                {t(AUTH_CONSTANTS.EMAIL)}
              </Text>
            </div>

          </Col>
          <Col xs={8} >
            <FormInput
              style={{
                width: "300px",
                borderRadius: "8px",
                border:
                  get(touched, "password", false) &&
                    get(errors, "password", "") ? "1px solid #F96565" : ""
              }}
              name={"email"}
              type={"email"}
              error={get(touched, "email", false) && get(errors, "email", "")}
              bg={true}
              value={get(values, "email", "")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue("email", e.target.value.replace(/\s/g, ""))
              }
            />
          </Col>
        </Row>
        <Row className="mb-16">
          <Col xs={2}></Col>
          <Col
            xs={2}
          // className={"mb-16"}
          // style={{
          //   textAlign: "center",
          //   alignItems: "center",
          //   display: "flex",
          //   justifyContent: "center",
          // }}
          >
            <div style={{ textAlign: "center", marginTop: "8px" }}>
              <Text
                style={{
                  textAlign: "left",
                  width: "150px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: get(touched, "password", false) && get(errors, "password", "") ? "#F71B1B" : ""
                }}
              >
                {t(AUTH_CONSTANTS.PASSWORD)}
              </Text>
            </div>
          </Col>
          <Col xs={8}  >
            <FormInput
              error={get(touched, "password", false) && get(errors, "password", "")}
              style={{
                width: "300px",
                borderRadius: "8px",
                border: get(touched, "password", false) && get(errors, "password", "") ? "1px solid #F96565" : ""
              }}
              name={"password"}
              type={"password"}
              bg={true}
              value={get(values, "password", "")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue("password", e.target.value.replace(/\s/g, ""))
              }
            />
          </Col>
        </Row>
        {is2FA && (
        <Row className="mb-16">
          <Col xs={2}></Col>
          <Col
            xs={2}
          // className={"mb-16"}
          // style={{
          //   textAlign: "center",
          //   alignItems: "center",
          //   display: "flex",
          //   justifyContent: "center",
          // }}
          >
            <div style={{ textAlign: "center", marginTop: "8px" }}>
              <Text
                style={{
                  textAlign: "left",
                  width: "150px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: get(touched, "code", false) && get(errors, "code", "") ? "#F71B1B" : ""
                }}
              >
                {t(AUTH_CONSTANTS.CODE)}
              </Text>
            </div>
          </Col>
          <Col xs={8}  >
            <FormInput
              error={get(touched, "code", false) && get(errors, "code", "")}
              style={{
                width: "300px",
                borderRadius: "8px",
                border: get(touched, "code", false) && get(errors, "code", "") ? "1px solid #F96565" : ""
              }}
              name={"code"}
              type={"code"}
              bg={true}
              value={get(values, "code", "")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue("code", e.target.value)
              }
            />
          </Col>
        </Row>
        )}
        <Row>
          <Col xs={12} className={"mb-32"}>
            <Row>
              <Col xs={4}></Col>
              <Col xs={8} style={{ padding: "0 20px" }}>
                <Button
                  style={{
                    marginTop: "20px",
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                  type={"submit"}
                  p={"0 40px"}
                  loading={isSubmitting}
                  color="#0085FF"
                  radius={"8px"}
                >
                  {t(AUTH_CONSTANTS.LOGIN)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row>
          <Col xs={4}></Col>
          <Col style={{ marginBottom: "150px" }} xs={8} >
            <Text>
              <Link className={"reset__link"} to={"/change-email"}>
                {t(AUTH_CONSTANTS.FORGOT_PASSWORD)}
               
              </Link>
            </Text>
          </Col>
        </Row> */}
      </FormWrapper>
    </Container>
    // </StyledLoginForm>
  );
};

export default Login;
