import { Content } from "components";
import { Container, Flex, Text } from "@mantine/core";
import { Col, Row } from "react-grid-system";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
// import InfoPage from "./tabs/infoPage/info";
// import useMyAccount from "./useMyAccount";
import { get } from "lodash";
import { UserRole } from "shared/constants/permissions";
// import UpdatePassword from "./tabs/password/UpdatePassword";
import BaseBreadcrumb from "components/Bredcrump";
import { useTranslation } from "react-i18next";
import { PUSH_NOTEFICATION } from "../../constants";
import HistoryPage from "../tabs/historyPage/history";
import SendPage from "../tabs/sendPage/send";
import { useState } from "react";
function PushNotification() {
  const [tabActiv, setTabActiv] = useState<number>(1);
  //   const { profile } = useMyAccount();
  const { t } = useTranslation();
  const items = tabActiv == 2 ? [
    { url: "", id: "1", name: t(PUSH_NOTEFICATION.PUSH_NOTEFICATIONS) },
    { url: "", id: "2", name: t(PUSH_NOTEFICATION.HISTORY) },
  ] : [
    { url: "", id: "1", name: t(PUSH_NOTEFICATION.PUSH_NOTEFICATIONS) },
    { url: "", id: "2", name: t(PUSH_NOTEFICATION.SEND) },
  ];
  return (
    <>
      <BaseBreadcrumb items={items} />
      <Content style={{ marginTop: "-8px" }} height={"80vh"}>
        <Row className="" >
          <Col xs={12}>
            <Tabs tabIndex={1}>
              <TabList>
                <Tab onClick={() => setTabActiv(1)}>{t(PUSH_NOTEFICATION.SEND)}</Tab>
                <Tab onClick={() => setTabActiv(2)}>{t(PUSH_NOTEFICATION.HISTORY)}</Tab>
              </TabList>
              <TabPanel>
                <SendPage />
              </TabPanel>
              <TabPanel>
                <HistoryPage />
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
      </Content>
    </>
  );
}

export default PushNotification;
