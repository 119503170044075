import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { StyledBaseProgress } from "./style";

const BaseProgress = ({ percentage = 50 }:{percentage?:number}) => {
    return (
        <StyledBaseProgress>
            <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({
                    pathTransitionDuration: 0.5,
                    pathColor: `#0085FF`,
                    textColor: "#0085FF",
                    trailColor: "#E6F3FF",
                    backgroundColor: "#0085FF",
                })}
            />
        </StyledBaseProgress>
    );
};

export default BaseProgress;
