import homeServices from "services/api/home";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { initialState } from "./types";
import usersServices from "services/api/users";

const InitialState: initialState = {
    users: {
        data: [],
        pagination: {},
        loading: false,
        error: ''
    },
    totals: {
        data: {},
        loading: false,
        error: ''
    },
    filter: {
     page: 0,
     size: 10,
     search: '',
     status: ''
    }
};

export const fetchGetAllUsers = createAsyncThunk(
    "users/fetchGetAllUsers",
    async (params: any) => {
        const request = await usersServices.getAllUsers(params);
        if (get(request, "status", "") != 200) {
            return get(request, "message", "");
        }
        const { data, pagination } = await request.data;
        return { data, pagination };
    }
);
export const fetchGetTotalUsers = createAsyncThunk(
    "users/fetchGetTotalUsers",
    async () => {
        const request = await homeServices.dashboardUsers();
        if (get(request, "status", "") != 200) {
            return get(request, "message", "");
        }
        const respond = await request.data;
        return respond;
    }
)


const usersSlices = createSlice({
    name: "users",
    initialState: InitialState,
    reducers: {
        handleFilterUsers:(state, action) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]:action.payload.value
            }
        },
        handleClearUsers:(state) => {
            state.filter = InitialState.filter
        }
    },
    extraReducers: (builder) => {
        // users
        builder.addCase(fetchGetAllUsers.pending, (state) => {
            state.users = {
                data: [],
                pagination: {},
                loading: true,
                error: ''
            }
        });
        builder.addCase(fetchGetAllUsers.fulfilled, (state, actions) => {
            state.users = {
                data: get(actions, "payload.data", []),
                pagination: get(actions, "payload.pagination", {}),
                loading: false,
                error: ''
            }
        });
        builder.addCase(fetchGetAllUsers.rejected, (state, actions) => {
            state.users = {
                data: [],
                pagination: {},
                loading: false,
                error: get(actions, "error.message", '')
            }
        });
        // total users
        builder.addCase(fetchGetTotalUsers.pending, (state) => {
            state.totals = {
                data: {},
                loading: true,
                error: ''
            }
        });
        builder.addCase(fetchGetTotalUsers.fulfilled, (state, actions) => {
            state.totals = {
                data: get(actions, "payload.data", {}),
                loading: false,
                error: ''
            }
        });
        builder.addCase(fetchGetTotalUsers.rejected, (state, actions) => {
            state.totals = {
                data: {},
                loading: false,
                error: get(actions, "error.message", '')
            }
        }); 
    },
});

export const {handleFilterUsers,handleClearUsers } = usersSlices.actions;
export default usersSlices.reducer;
