import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "./GlobalStyle";
import { Children } from "shared/types/GlobalTypes";

const Theme = ({ children }: Children) => {
    return (
        <>
            <GlobalStyle />
            {children}
            <ToastContainer autoClose={2000} />
        </>
    );
};

export default Theme;
