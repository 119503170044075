import homeServices from "services/api/home";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { initialState } from "./type";
import pushServices from "services/api/push";

const InitialState: initialState = {
    notfications: {
        data: {},
        pagination: {},
        loading: false,
        error: ''
    },
    notfcationDetails: {
        data:{},
        loading: false,
        error: ''
    },
    filter: {
        page: 1,
        size: 10,
        search: '',
        fromDate: '',
        toDate: '',
    }
};

export const fetchGetNotfcations = createAsyncThunk(
    "push/fetchGetNotfcations",
    async (params: any) => {
        const request = await pushServices.getNitficationHistory(params);
        if (get(request, "status", "") != 200) {
            return get(request, "message", "");
        }
        const { data, pagination } = await request.data;
        return { data, pagination };
    }
);
export const fetchGetNotfcationDetails = createAsyncThunk(
    "push/fetchGetNotfcationDetails",
    async (id: any) => {
        const request = await pushServices.getByIdNotfication(id);
        if (get(request, "status", "") != 200) {
            return get(request, "message", "");
        }
        const respond = await request.data;
        return respond;
    }
)


const pushSlices = createSlice({
    name: "push",
    initialState: InitialState,
    reducers: {
        handlePushFilter: (state, action) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value
            }
        },
        handlePushFilterClear: (state) => {
            state.filter = InitialState.filter
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetNotfcations.pending, (state) => {
            state.notfications = {
                data: [],
                pagination: {},
                loading: true,
                error: ''
            }
        });
        builder.addCase(fetchGetNotfcations.fulfilled, (state, actions) => {
            state.notfications = {
                data: get(actions, "payload.data", []),
                pagination: get(actions, "payload.pagination", {}),
                loading: false,
                error: ''
            }
        });
        builder.addCase(fetchGetNotfcations.rejected, (state, actions) => {
            state.notfications = {
                data: [],
                pagination: {},
                loading: false,
                error: get(actions, "error.message", '')
            }
        });

        // by id 
        builder.addCase(fetchGetNotfcationDetails.pending, (state) => {
            state.notfcationDetails = {
                data: {},
                loading: true,
                error: ''
            }
        });
        builder.addCase(fetchGetNotfcationDetails.fulfilled, (state, actions) => {
            state.notfcationDetails = {
                data: get(actions, "payload.data", {}),
                loading: false,
                error: ''
            }
        });
        builder.addCase(fetchGetNotfcationDetails.rejected, (state, actions) => {
            state.notfcationDetails = {
                data: {},
                loading: false,
                error: get(actions, "error.message", '')
            }
        });

    },
});

export const {handlePushFilterClear,handlePushFilter } = pushSlices.actions;
export default pushSlices.reducer;
