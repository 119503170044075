import { createSelector } from "@reduxjs/toolkit";
import { selectGetAllUsers, selectGetTotalUsers, selectUsersFilter } from "app/selectors/users";

export const getAllUsers = createSelector(
  [selectGetAllUsers],
  (data) =>data);
export const usersFilter = createSelector(
  [selectUsersFilter],
  (data) =>data
);
export const usersTotals = createSelector(
  [selectGetTotalUsers],
  (data) =>data
)