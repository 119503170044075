import styled from "styled-components";
import { StyledFormTextareaProps } from "./types";

export const StyledFormTextarea = styled.div<StyledFormTextareaProps>`
  width: 100%;
  textarea {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    width: ${({ width }) => width || "100%"};
    border: 1px solid ${({ error }) => (error ? "#E92C2C" : "#E8E8E8")};
    padding: 8px 12px;
    outline: none;
    color: #1c1c1c;
    font-weight: 400;
    font-size: 14px;
    min-height: ${({ height }) => height || "100px"};
    font-family: "Inter", sans-serif;
    background-color: ${({ bg }) => (bg ? "#FFFFFF" : "#F2F2F2")};
    :active {
      border: 1px solid #00A6FF;
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.7);
    }
  }
`;
