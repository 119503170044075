import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hook";
import { logOut, profileInfo } from "app/slices/authSlices/authSlices";
import { authData } from "app/states/auth";
import { Menu } from "components";
import ScrollToTop from "routers/ScrollToTop";
import { Auth } from "shared/constants/path";
import { get, isEqual } from "lodash";
import { Col, Container, Row } from "react-grid-system";
import { Navigate, Outlet } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

const MainLayout = () => {
  const userAuthData = useAppSelector(authData);
  const isAuthenticated = get(userAuthData, "isAuthenticated", false);
  const userName = get(userAuthData, "userData.data.firstName", "...")+" "+get(userAuthData, "userData.data.lastName", "...");
  console.log("userAuthData", userAuthData);
  const isToken = get(userAuthData, "token", false);
  const hasRefreshed = localStorage.getItem("hasRefreshed");
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(profileInfo()).then((res: any) => {
      if (isEqual(get(res, "meta.requestStatus", ""), "rejected")) {
        dispatch(logOut());
      }
    });
  }, [dispatch]);
  useEffect(() => {
    if (!hasRefreshed) {
      localStorage.setItem("hasRefreshed", "true");
    }
  }, [isAuthenticated]);
  return (
    <ScrollToTop>
      {isAuthenticated ? (
        <Fragment>
          <Menu userName={userName} />
          <Container fluid style={{ marginTop: "60px" }}>
            <Row>
              <Col xs={12}>
                <Outlet />
              </Col>
            </Row>
          </Container>
        </Fragment>
        ) : (
         <Navigate to={Auth.RELOAD} replace />
       )} 
    </ScrollToTop>
  );
};

export default MainLayout;
