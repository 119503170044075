import { memo } from "react";
import { StyledContent } from "./style";
import isPropValid from "@emotion/is-prop-valid";
import { StyleSheetManager } from "styled-components";

const Content = ({ rounded, ...props }: any) => {
  return <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}> <StyledContent rounded={rounded ? 'true' : undefined} {...props} /></StyleSheetManager>;
};

export default memo(Content);
