import styled from "styled-components";
import { StyleProps } from "./type";
export const MenuWrapper = styled.div`
  position: fixed;
  top: 0%;
  width: 100%;
  background: #ffffff;
  padding: 5px 0;
  z-index: 100;
`;
const MenuItemWrapper = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  height: 50px;
  /* position: fixed; */
  .menu-item {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    span {
      color: black;
    }
    &.active {
      .menu-icon {
        svg {
          path {
            fill: #0085ff;
          }
        }
      }

      span {
        color: #0085ff;
      }
    }

    &:hover {
      .menu-icon {
        svg {
          path {
            fill: #0085ff;
          }
        }
      }

      span {
        color: #0085ff;
      }
    }
  }
  .active {
      .menu-icon {
        svg {
          path {
            fill: #0085ff;
          }
        }
      }

      span {
        color: #0085ff;
      }
    }
`;

export { MenuItemWrapper };
