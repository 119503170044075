import ProfileServices from "services/api/profile";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { GLOBAL_CONSTANTS } from "modules/globalConstants";
import { useTranslation } from "react-i18next";

const ConnectPassword = () => {
  const { t } = useTranslation();
  const onSubmit = (values: any) => {
    try {
      ProfileServices.ChangePassword({ ...values }).then((res: any) => {
        setSubmitting(false);
        if (res.status == 200) {
        
          toast.success(t(GLOBAL_CONSTANTS.SUCCESS));
          resetForm();
        }
      });
    } catch (err) {
      setSubmitting(false);
    }
  };

  const verfySchema = object({
    currentPassword: string().trim().required("old password is required"),
    newPassword: string().trim().required("new password is required"),
    confirmPassword: string().trim().required("confirm password is required"),
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit,
    validationSchema: verfySchema,
  });
  return {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    isSubmitting,
  };
};
export default ConnectPassword;
