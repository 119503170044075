import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchGetAllUsers, fetchGetTotalUsers, handleClearUsers, handleFilterUsers } from "app/slices/usersSlices/usersSlices";
import { getAllUsers, usersFilter, usersTotals } from "app/states/users";
import { useCallback, useEffect } from "react";

const useConnect = () => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector(usersFilter);
    const data = useAppSelector(getAllUsers);
    const totals = useAppSelector(usersTotals);
    const fetchUsers = useCallback(() => {
        dispatch(fetchGetAllUsers(filter));
        dispatch(fetchGetTotalUsers());
    }, [filter.page, filter.size]);
    const fetchFilterUsers = useCallback(() => {
        dispatch(fetchGetAllUsers(filter));
    }, [filter]);
    const handleFilter = useCallback(({ name, value }: { name: string, value: number | string }) => {
        dispatch(handleFilterUsers({ name, value }));
    }, []);
    const handleReset = useCallback(() => {
        dispatch(handleClearUsers());
        dispatch(fetchGetAllUsers({ ...filter, search: '', status: '' }));
    }, [filter]);
    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);
    useEffect(() => {
        return () => {
            dispatch(handleClearUsers());
            dispatch(handleFilterUsers({ name: "page", value: 0 }));
            dispatch(handleFilterUsers({ name: "size", value: 10 }));
        }
    }, []);
    return {
        data,
        filter,
        handleFilter,
        fetchFilterUsers,
        handleReset,
        totals
    };
}
export default useConnect;