export enum UserRole {
  ADMIN = "SUPER_ADMIN",
  DEALER = "DIALER",
}

export enum Permissions {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export enum PAGES {
  SDELKA = "SDELKA",
  SERVICE = "SERVICE",
  SPRAVOCHNIK = "SPRAVOCHNIK",
  PRICE = "PRICE",
  PRIXOD = "PRIXOD",
  VOZVRAT = "VOZVRAT",
  DEALER_SEARCH = "DEALER_SEARCH",
  STORAGE = "STORAGE",
  RETURN = "RETURN",
  RASXOD = "RASXOD",
  BRAND = 'BRAND',
  SPARES = 'SPARES',
  OSTATKA="OSTATKA",
  KLENT ="KLENT"
}
export type CrudActions = "CREATE" | "READ" | "UPDATE" | "DELETE";
