import { useAppSelector } from "app/hook";
import { authData } from "app/states/auth";
import { CrudActions, PAGES, UserRole } from "shared/constants/permissions";
import {
  AbilityBuilder,
  MongoQuery,
  PureAbility,
  createMongoAbility,
} from "@casl/ability";
import { get } from "lodash";
import { AnyIfEmpty } from "react-redux";



export type AbilityType = [
  CrudActions,
  (
    | "all"
    | PAGES.SDELKA
    | PAGES.SERVICE
    | PAGES.SPRAVOCHNIK
    | PAGES.PRICE
    | PAGES.PRIXOD
    | PAGES.VOZVRAT
    | PAGES.RASXOD
    | PAGES.DEALER_SEARCH
    | PAGES.BRAND
    | PAGES.SPARES
    | PAGES.OSTATKA
    | PAGES.KLENT
  )
];

export type AppAbility = PureAbility<AbilityType, MongoQuery>;

export function useDefineAbilityFor () {
  const { can, build } = new AbilityBuilder<AppAbility>(createMongoAbility);

  const userAuthData = useAppSelector(authData);
  const user:any = get(userAuthData, "userData.data.role", "DEALER");
  const enable: boolean = get(userAuthData, "userData.data.enable", false);

  // user ROLE checking CRUD permissions ------------------------------------------------
  if (user === UserRole.DEALER) {
    if (enable) {
      can("CREATE", PAGES.SDELKA);
      can("UPDATE", PAGES.SDELKA);
      can("DELETE", PAGES.SDELKA);
      can('READ', PAGES.SDELKA);
      // SERVICE
      can("UPDATE", PAGES.SERVICE);
      can("CREATE", PAGES.SERVICE);

      // VOZVRAT
      can('CREATE', PAGES.VOZVRAT);
      can('UPDATE', PAGES.VOZVRAT);
      can('DELETE', PAGES.VOZVRAT);
      can('READ', PAGES.VOZVRAT);

      // READS
      can("READ", PAGES.SPRAVOCHNIK);
      can("READ", PAGES.SPARES);
      can("READ", PAGES.PRIXOD);
      can('READ', PAGES.OSTATKA);
      can('READ', PAGES.KLENT);
      can('READ', PAGES.SERVICE);
    }
  } else {
    // SPARES
    can("CREATE", PAGES.SPARES);
    can("DELETE", PAGES.SPARES);
    can("UPDATE", PAGES.SPARES);
    can("READ", PAGES.SPARES);
    // BRAND
    can("CREATE", PAGES.BRAND);
    can("DELETE", PAGES.BRAND);
    can("UPDATE", PAGES.BRAND);
    // SPRAVOCHNIK
    can("CREATE", PAGES.SPRAVOCHNIK);
    can("DELETE", PAGES.SPRAVOCHNIK);
    can("UPDATE", PAGES.SPRAVOCHNIK);
    can('READ', PAGES.SPRAVOCHNIK);
    // PRICE
    can("DELETE", PAGES.PRICE);
    can("CREATE", PAGES.PRICE);
    // VOZVRAT
    can("CREATE", PAGES.VOZVRAT);
    can("READ", PAGES.RASXOD);
    // PRIXOD
    can("DELETE", PAGES.PRIXOD);
    can("UPDATE", PAGES.PRIXOD);
    can("CREATE", PAGES.PRIXOD);
    can("READ", PAGES.PRIXOD);
    // READS
    can('READ', PAGES.SDELKA);
    can("READ", PAGES.DEALER_SEARCH);
    can('READ', PAGES.OSTATKA);
    can('READ', PAGES.KLENT);
    can('READ', PAGES.SERVICE);
  }

  return build();
}
