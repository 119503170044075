import { StyledText } from "./style";

const Text = (props: any) => {
  return <StyledText
    {...props}
    danger={props.dager ? true : false}
    tangerine={props.tangerine ? true : false}
    dark={props.dark ? true : false}
    light={props.light ? true : false}
    green={props.green ? true : false}
    gray={props.gray ? true : false}
    success={props.success ? true : false}
    primary={props.primary ? true : false}
    blue={props.blue ? true : false}
    info={props.info ? true : false}
    lightDark={props.lightDark ? true : false}
    review={props.review ? true : false}
    medium={props.medium ? true : false}
    bold={props.bold ? true : false}
    xxl={props.xxl ? true : false}
    xl={props.xl ? true : false}
    large={props.large ? true : false}
    small={props.small ? true : false}
    xs={props.xs ? true : false}
    xxs={props.xxs ? true : false}
  />;
};

export default Text;
