import styled from "styled-components";

 const ProfileContainerWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    .profile-settings {
        &.active {
            svg {
                path {
                    fill: #0085ff !important;
                    stroke-dashoffset: 0;
                    stroke-dasharray: 700;
                }
            }
        }
    }
`;

const Avatar = styled.img`
    height: 32px;
    width: 32px;
    border-radius: 32px;
    border: 1px solid #e8e8e8;
`;

export { ProfileContainerWrap, Avatar };
