
const useConnect = () => {
    const totals ={
        loading: true,
        data:{
            totalSessions: 0,
            free: 0,
            freeFailed: 0,
            paid: 0,
            paidFailed: 0,
            freeIn24Hours: 0,
            paidIn24Hours: 0
        }
    }
    const data ={
        loading: true,
        data: [],
        pagination: {},
        error: ''
    }
    return {
        totals,
        data
    }
}

export default useConnect