import ProfileInput from "components/ProfilInput";
import { Text } from "@mantine/core";
import { Col, Row } from "react-grid-system";
import { get } from "lodash";
import { BaseButton, BaseInput, BaseModal, BaseSelect, BaseTextarea, Content, Flex, Loading } from "components";
import useConnectInfo from "./connect";
import { useLocation } from "react-router-dom";
import { PROFILE_CONSTANTS } from "modules/profile/constants";
import { useTranslation } from "react-i18next";
import { PUSH_NOTEFICATION } from "modules/push/constants";
import ProfileSelect from "components/ProfileSelect";
import { ReactSVG } from "react-svg";
import photoUpload from "assets/icons/photoUpload.svg";
import React from "react";

function SendPage() {
  const {
    // region,
    // districts,
    openTextModal, 
    setOpenTextModal,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
    image,
    loading,
    handleImageUpload
  } = useConnectInfo();
  

  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <BaseModal
        close={() => setOpenTextModal(false)}
        opened={openTextModal}
        isDelete={false}
        // title={t(GLOBAL_CONSTANTS.DATA_ENTRY)}
      >
        <form onSubmit={handleSubmit}>
          <Row className="mb-12">
            <Col xs={12} >
              <Text
                className="label"
                style={{
                  textAlign: "left",
                  // color: errors.image && touched.image ? "#D80027" : "#1C1C1C",
                }}
              >
                {t(PROFILE_CONSTANTS.IMAGE)}
              </Text>
              <Content
                height={"200px"}
                style={{
                  boxShadow: "none",
                  border: "1px solid #E5E5E5",
                }}
              >
                <label
                  htmlFor="upload"
                  style={{ width: "100%", height: "100%"}}
                >
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                        marginTop: "70px",
                      }}
                    >
                      <Loading h="100%" />
                    </div>
                  ) : image ? (
                    <img
                      src={image}
                      alt="image"
                      style={{
                        width: "100%",
                        height: "220px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <>
                      <ReactSVG
                        src={photoUpload as any}
                        style={{ width: "100%", height: "100%" }}
                      />
                      <Text
                        style={{
                          textAlign: "center",
                          // color:
                          //   errors.image && touched.image
                          //     ? "#D80027"
                          //     : "#1C1C1C",
                        }}
                      >
                        {t(PUSH_NOTEFICATION.UPLOAD_IMAGE)}
                      </Text>
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id="upload"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                />
              </Content>
            </Col>
          </Row>

          <Flex className="mt-24"  justify={"end"}>
            <BaseButton loading={isSubmitting} type="submit" green>
              {t(PUSH_NOTEFICATION.APPLY)}
            </BaseButton>
            <BaseButton
              handleClick={() => {
                setOpenTextModal(false);
                setFieldValue("id", null);
                setFieldValue("name", "");
                setFieldValue("image", "");
              }}
              danger
            >
              {t(PUSH_NOTEFICATION.RESET)}
            </BaseButton>
          </Flex>
        </form>
      </BaseModal>
      <form
        onSubmit={handleSubmit}
        style={{ marginTop: "20px", marginBottom: "20px", marginLeft: "10px" }}
      >
        <Row>
          <Col xs={1}></Col>
          <Col xs={6}>
            <Row>
              <Col xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Text>{t(PUSH_NOTEFICATION.USER_TYPE)}</Text>
              </Col>
              <Col xs={8}>
                <BaseSelect
                  name="fullName"
                  bg
                  placeholder={t(PUSH_NOTEFICATION.PLACEHOLDER_STATUS)}
                  options={[
                    { value: 'ALL', label: t(PUSH_NOTEFICATION.ALL) },
                    { value: "ACTIVE", label: t(PUSH_NOTEFICATION.ACTIVE) },
                    { value: "INACTIVE", label: t(PUSH_NOTEFICATION.INACTIVE) },
                  ]}
                  label={t(PUSH_NOTEFICATION.USER_TYPE)}
                  value={get(values, "fullName", "")}
                  onChange={(e: any) => setFieldValue("fullName", e.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4} style={{ display: 'flex', alignItems: 'center' }}>
            <Text >{t(PUSH_NOTEFICATION.TOTAL_USERS)}: 15,700</Text>
          </Col>
        </Row>
        <Row className="mt-16">
          <Col xs={1}></Col>
          <Col xs={8}>
            <Row>
              <Col xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                <Text>{t(PUSH_NOTEFICATION.TITLE)}</Text>
              </Col>
              <Col xs={9}>
                <BaseInput bg />
              </Col>
            </Row>

          </Col>
        </Row>
        <Row className="mt-16">
          <Col xs={1}></Col>
          <Col xs={8}>
            <Row>
              <Col xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                <Text>{t(PUSH_NOTEFICATION.MESSAGE)}</Text>
              </Col>
              <Col xs={9}>
                <BaseTextarea bg />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-16">
          <Col xs={1}></Col>
          <Col xs={6}>
            <Row>
              <Col xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Text>{t(PUSH_NOTEFICATION.THUMBNAIL)}</Text></Col>
              <Col xs={8}>
                <BaseInput bg />
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <BaseButton  width="150px"   onClick={() => setOpenTextModal(true)} >{t(PUSH_NOTEFICATION.UPLOAD_IMAGE)}</BaseButton>
          </Col>
        </Row>
        <Row className="mt-16">
          <Col xs={1}></Col>
          <Col xs={6} >
            <Row>
              <Col xs={4} style={{ display: 'flex', alignItems: 'center' }} >
                <Text>{t(PUSH_NOTEFICATION.TEST)}</Text></Col>
              <Col xs={8}>
                <BaseInput bg />
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <BaseButton width="150px" >{t(PUSH_NOTEFICATION.SEND_TEST)}</BaseButton>
          </Col>
        </Row>
        <Row justify="center" className="mt-16">
          <BaseButton  type="submit" loading={isSubmitting} primary>
            {t(PUSH_NOTEFICATION.SEND)}
          </BaseButton>
        </Row>
      </form>
    </>
  );
}

export default SendPage;
