import isPropValid from '@emotion/is-prop-valid';
import React from 'react';
import styled, { css, StyleSheetManager } from 'styled-components';

interface StyledBaseBadgeProps {
  primary?: boolean;
  lg?: boolean;
  children?: React.ReactNode;
}

const StyledBaseBadge = styled.span<StyledBaseBadgeProps>`
  display: inline-block;
  border-radius: 96px;
  color: #fff;
  padding: 4px;
  font-size: 10px;
  font-weight: 600;
  ${({ primary }:StyledBaseBadgeProps) =>
    primary &&
    css`
      background-color: #0085ff;
    `};
  ${({ lg }:StyledBaseBadgeProps) =>
    lg &&
    css`
      padding: 4px 8px;
    `};
`;

const BaseBadge: React.FC<StyledBaseBadgeProps> = (props) => {
  return <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}><StyledBaseBadge {...props} /></StyleSheetManager>;
};

export default BaseBadge;
