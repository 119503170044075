import React, { memo } from "react";
import { BasePaginationProps } from "./types";
import ReactPaginate from "react-paginate";

const BasePagination: React.FC<BasePaginationProps> = ({
  total = 3,
  value,
  handleChange,
}) => {


  return (
    <ReactPaginate
      previousLabel="<"
      nextLabel=">"
      breakLabel="..."
      pageCount={total}
      onPageChange={handleChange}
      pageRangeDisplayed={3}
      containerClassName={"pagination_container"}
      activeClassName={"pagination_active"}
      disabledClassName={"pagination_disabled"}
      forcePage={value} // Ensure `forcePage` is valid
    />
  );
};

export default  memo(BasePagination);
