import { Link } from "react-router-dom";
import { StyledBaseBreadcrumb } from "./style";
import Text from "../Text";
import right from "assets/icons/arrow_right_navigation.svg";
import { ReactSVG } from "react-svg";
import BaseList from "../BaseLIst";

interface BreadcrumbItem {
  id: string | number | null;
  url: string;
  name: string;
}

interface BaseBreadcrumbProps {
  items?: BreadcrumbItem[];
  [key: string]: any;
}

const BaseBreadcrumb = ({ items = [], ...props }: BaseBreadcrumbProps) => {
  return (
    <StyledBaseBreadcrumb {...props}>
      <BaseList items={items}>
        {({ id, url, name }: BreadcrumbItem, index: number) => (
          <li key={id}>
            {items.length !== index + 1 ? (
              <Link to={items.length !== index + 1 ? url : "#"}>
                <Text medium xl>
                  {name}
                </Text>
              </Link>
            ) : (
              <Text medium xl>
                {name}
              </Text>
            )}
            {items.length !== index + 1 && (
              // "->"
              <ReactSVG
                className="arrow"
                style={{ width: "20px" }}
                src={right as any}
              />
            )}
          </li>
        )}
      </BaseList>
    </StyledBaseBreadcrumb>
  );
};

export default BaseBreadcrumb;
