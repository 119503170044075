export const PUSH_NOTEFICATION = {
  PUSH_NOTEFICATIONS: "push_notifications",
  HISTORY: "history",
  SEND: "send",
  USER_TYPE: "user_type",
  VIEW: "view",

  TITLE: "title",
  MASSAGE: "message",
  PLACEHOLDER_STATUS: "placeholder_status",
  ALL: "all",
  ACTIVE: "active",
  INACTIVE: "inactive",
  TOTAL_USERS: "total_users",
  MESSAGE: "message",

  THUMBNAIL: "thumbnail",
  TEST: "test",
  UPLOAD_IMAGE: "upload_image",
  SEND_TEST: "send_test",
  APPLY: "apply",
  RESET: "reset",
  STATUS: "status",
  SENT_DATE: "sent_date",
  DELIVRIES: "delivries",
  OPENS: "opens",
  ACTIONS: "actions",
  SHOW: "show",
  ON_THE_PAGE: "on_the_page",
  SENT_DATE_AND_TIME: "sent_date_and_time",
};
