import { useAppDispatch, useAppSelector } from "app/hook";
import { profileInfo } from "app/slices/authSlices/authSlices";
import { authData } from "app/states/auth";
import { useFormik } from "formik";
import { get } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { GLOBAL_CONSTANTS } from "modules/globalConstants";
import { useTranslation } from "react-i18next";

const verfySchema = object({
  fullName: string().trim().required("full name is required"),
  address: string().trim().required("address is required"),
});

const useConnectInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuthData = useAppSelector(authData);
  const [openTextModal, setOpenTextModal] = React.useState(false);
  const [loading,setLoading] = React.useState(false);
  const [image ,setImage] = React.useState<any>(null);
  const { t } = useTranslation();
  const profile = get(userAuthData, "userData.data", {});
  const handleImageUpload = (e: any) => {
    const file = get(e, "target.files[0]", null);
    if (file) {
      setFieldValue("image", file);
      setLoading(true); // Start loading
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setLoading(false); // Stop loading
      };
      reader.readAsDataURL(file);
    }
  };
  const onSubmit = (values: any) => {
    // DealersServices.PutDealer({ id: profile.id, params: values })
    //   .then((res: any) => {
    //     setSubmitting(false);
    //     if (res.status == 200) {
    //       toast.success(t(GLOBAL_CONSTANTS.UPDATED));
    //       dispatch(profileInfo());
    //     }
    //   })
    //   .catch(() => setSubmitting(false));
  };

  const {
    handleSubmit,
    values,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    errors,
    touched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: get(profile, "fullName", ""),
      phoneNumber: get(profile, "phoneNumber", ""),
      region: get(profile, "region", ""),
      email: get(profile, "email", ""),
      address: get(profile, "address", ""),
      regionId: get(profile, "regionId", ""),
      districtId: get(profile, "districtId", ""),
    },
    onSubmit,
    validationSchema: verfySchema,
  });

  const fetchDistrictById = useCallback(() => {
    // if (get(values, "regionId", null)) {
    //   dispatch(fetchGetDistrict(String(get(values, "regionId", null))));
    // }
  }, [dispatch, values.regionId]);

  useEffect(() => {
    // dispatch(fetchGetRegion());
    fetchDistrictById();
  }, [fetchDistrictById, dispatch]);

  return {
    profile,
    // region,
    errors,
    touched,
    // data,
    handleImageUpload,
    // districts,
    image,
    loading,
    navigate,
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    openTextModal, 
    setOpenTextModal
  };
};

export default useConnectInfo;
