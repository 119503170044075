import { Container } from "@mantine/core";
import {
  BaseButton,
  BaseInput,
  BaseProgress,
  BaseSelect,
  BaseTextarea,
  Content,
  Flex,
  Text,
} from "components";
import BaseBreadcrumb from "components/Bredcrump";
import { get, isEqual } from "lodash";
import { HOMECONSTANT } from "modules/home/constants";
import { PUSH_NOTEFICATION } from "modules/push/constants";
import { useState } from "react";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useConnect from "./connect";
import { ReactSVG } from "react-svg";
import check1 from "assets/icons/check_circle.svg";
import check2 from "assets/icons/check_circle (1).svg";
import { NumericFormat } from "react-number-format";
import { Main } from "shared/constants/path";

function Index() {
  const { setFieldValue, LAST_NOTIFIES, values, handleSubmit, isSubmitting } =
    useConnect();
  const { t } = useTranslation();
  const items = [
    { url: Main.PUSH, id: "1", name: t(PUSH_NOTEFICATION.PUSH_NOTEFICATIONS) },
    { url: "", id: "2", name: t(PUSH_NOTEFICATION.VIEW) },
  ];
  return (
    <>
      <BaseBreadcrumb items={items} />
      <Col className="p-2" style={{ marginTop: "-8px" }}>
        <Row className="">
          <Col xs={12} style={{ paddingLeft: "0" }}>
            <Content
              height={"100%"}
              className={"mb-16"}
              rounded
              style={{
                // margin: "10px, 20px",
                // // width: "80%",
                paddingTop: "60px",
                paddingButton: " 140px",
                paddingLeft: " 140px",
                display: "flex",
                justifyContent: "spase-between",
              }}
            >
              <Col xs={7}>
                <Row className="mt-16 ">
                  <Col xs={8}>
                    <Row>
                      <Col xs={4}>
                        <Text>{t(PUSH_NOTEFICATION.USER_TYPE)}</Text>
                      </Col>
                      <Col xs={8}> Active</Col>
                    </Row>
                    <hr
                      style={{
                        height: "1px",
                        backgroundColor: "#E8E8E8",
                        marginTop: "15px",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-16">
                  <Col xs={11}>
                    <Row>
                      <Col
                        xs={3}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Text>{t(PUSH_NOTEFICATION.TITLE)}</Text>
                      </Col>
                      <Col xs={8}>Yangi Yil muborak</Col>
                    </Row>
                    <hr
                      style={{
                        height: "1px",
                        backgroundColor: "#E8E8E8",
                        marginTop: "15px",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-16">
                  <Col xs={11}>
                    <Row>
                      <Col
                        xs={3}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Text>{t(PUSH_NOTEFICATION.MASSAGE)}</Text>
                      </Col>
                      <Col xs={8}></Col>
                    </Row>
                    <hr
                      style={{
                        height: "1px",
                        backgroundColor: "#E8E8E8",
                        marginTop: "15px",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-16">
                  <Col xs={11}>
                    <Row>
                      <Col
                        xs={3}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Text>{t(PUSH_NOTEFICATION.THUMBNAIL)}</Text>
                      </Col>
                      <Col xs={8}></Col>
                    </Row>
                    <hr
                      style={{
                        height: "1px",
                        backgroundColor: "#E8E8E8",
                        marginTop: "15px",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-16">
                  <Col xs={8}>
                    <Row>
                      <Col
                        xs={6}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Text>{t(PUSH_NOTEFICATION.SENT_DATE_AND_TIME)}</Text>
                      </Col>
                      <Col xs={8}></Col>
                    </Row>
                    <hr
                      style={{
                        height: "1px",
                        backgroundColor: "#E8E8E8",
                        marginTop: "15px",
                      }}
                    />
                  </Col>
                </Row>
                <Row justify="center" className="mt-16"></Row>
              </Col>
              <Col
                xs={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "-50px",
                }}
              >
                <Row align={"center"}>
                  <Col
                    xs={4}
                    style={{ textAlign: "center", marginTop: "10px" }}
                  >
                    <Flex justify={"center"}>
                      <BaseProgress percentage={76} />
                    </Flex>
                  </Col>
                  <Col xs={8}>
                    <Flex align="center" className={"mb-8"}>
                      <ReactSVG className={"check-icon"} src={check1 as any} />
                      <Text gray>
                        {" "}
                        {t(HOMECONSTANT.DELIVERED)}:{" "}
                        <NumericFormat
                          displayType="text"
                          value={23}
                          thousandSeparator
                        />
                      </Text>
                    </Flex>
                    <Flex align="center">
                      <ReactSVG className={"check-icon"} src={check2 as any} />
                      <Text gray>
                        {" "}
                        {t(HOMECONSTANT.OPENED)}:{" "}
                        <NumericFormat
                          displayType="text"
                          value={76}
                          thousandSeparator
                        />
                      </Text>
                    </Flex>
                  </Col>
                </Row>
              </Col>
            </Content>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default Index;
