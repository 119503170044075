import { ReactSVG } from "react-svg";
import styled from "styled-components";

const StyledBaseLineChart = styled.div`
  height: ${({ height }: any) => height || "500px"};
  width: ${({ width }: any) => width || "100%"};

  .recharts-legend-wrapper {
    left: 65px !important;
    bottom: 0px !important;
  }
  .recharts-tooltip-wrapper {
    background: #ffffff !important;
    box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1),
      0px 9px 46px rgba(0, 0, 0, 0.06), 0px 24px 38px rgba(0, 0, 0, 0.04) !important;
    border-radius: 8px !important;
    border: none !important;
  }
`;

const StyledCustomTooltip = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* justify-content: space-between; */
  /* width: 236px; */
  min-width: ${({ width }: any) => width || "236px"};
  min-height: ${({ height }: any) => height || "100px"};
  height: max-content;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06),
    0px 24px 38px rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  ul {
    /* margin-left: 24px; */

    li {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      &::marker {
        content: " -:- ";
        font-size: 1.2em;
      }
    }
  }
`;

const LinerIcon = styled(ReactSVG)`
  &.svg {
    path {
      color: red;
      fill: "#509";
      /* ${({ color }: any) => color || "#999"}; */
      stroke: ${({ color }: any) => color || "#999"};
    }
  }
  color: red;
`;

const LineIconWrp = styled.div`
  position: relative;
  width: 25px;
  .graph-cnty-ln {
    background-color: ${({ color }: any) => color || "#999999"};
    width: 100%;
    height: 2px;
    border-radius: 10px;
  }
  .graph-cnty-crc {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: ${({ color }: any) => color || "#999999"};
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export { StyledBaseLineChart, StyledCustomTooltip, LinerIcon, LineIconWrp };
