import styled from "styled-components";
import { StyleProps } from "./type";
export const TableWrapper = styled.table<StyleProps>`
  text-align: center;
  position: relative;
  overflow: auto;
  width: 100%;
  border-collapse: collapse;
  margin: ${({ margin }: StyleProps) => margin || "0px"};
  min-height: 10vh;
  font-style: normal;
  overflow: ${({ overflow }: any) => overflow || "auto"};
  thead{
    tr{
      td{
        text-align: left;
        padding: 0px !important;
      }
    }
  }
  tbody {
    overflow-y: "auto";
  }
  tr {
    min-height: unset;
    th,
    td {
      padding: 15px;
    }

    th {
      min-width: ${({ minWidth }: StyleProps) => minWidth || "40px"};
      min-height: ${({ minHeight }: StyleProps) => minHeight || "40px"};
      font-size: 12px;
      color: #969696;
      padding: 15px;
      /* min-height: ${({ minHeight }: StyleProps) => minHeight || "40px"}; */

      font-weight: 500;
      border-bottom: 1px solid #e8e8e8;
    }
    td {
      font-size: 12px;
      line-height: 24px;
      color: #585757;
      font-weight: 500;
      border-bottom: 1px solid #e8e8e8;
      min-width: ${({ minWidth }: StyleProps) => minWidth || "40px"};
      /* max-width: ${({ maxWidth }: StyleProps) => maxWidth || "400px"}; */
      max-height: 50px;
      position: relative;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: nowrap;
    }
  }
`;
