import React, { ChangeEvent } from "react";
import { Col, Row } from "react-grid-system";
import { StyledFormInput } from "./style";
import { Input } from "@mantine/core";
import { FormInputProps } from "../FormInput/types";

const ProfileInput: React.FC<FormInputProps> = ({
  label,
  placeholder,
  value = "",
  handleOnChange,
  type,
  handleOnBlur,
  error,
  name,
  className,
  defaultValue,
  disabled = false,
  left = 4,
  right = 12,
  inputXS=7,
  id,
  ...props
}) => {

  return (
    <StyledFormInput {...props} error={error} className={className}>
      <Row align={"center"} style={{ marginBottom: "10px" }}>
        <Col xs={2.5} style={{ textAlign: "left" }} justify-content={"center"}>
          <label
            style={{ marginLeft: `0px`, fontSize: "16px", fontWeight: "500", color: error?"#f96565": "#585757" }}
            htmlFor={id}
          >
            {label}
          </label>
        </Col>
        <Col xs={inputXS} style={{ padding: '0' }}>
          <Input
            id={id}
            placeholder={placeholder}
            value={value ? value : ""}
            onChange={handleOnChange}
            type={type}
            onBlur={handleOnBlur}
            name={name}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        </Col>
        {/* <Col xs={12}>{error && <span className=" ml-8">{error}</span>}</Col> */}
      </Row>
    </StyledFormInput>
  );
};

export default ProfileInput;
