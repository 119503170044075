import styled, { css } from "styled-components";
import { StyleProps } from "./types";

const SelectWrapper = styled.div<StyleProps>`
  margin: ${({ margin }: StyleProps) => margin || "0px"};
  max-width: ${({ width }: StyleProps) => width || "100%"};
  min-width: ${({ width }: StyleProps) => width || "100%"};
  font-size: 14px;
  color: #1c1c1c;
  font-weight: 400;
  cursor: pointer;
  .dfwYy {
    width: 100%;
  }
  .select-arrow {
    margin-right: 18px;
    margin-left: 5px;
  }
  ${({ sm }: StyleProps) =>
    sm &&
    css`
      font-size: 13px;
    `};
  ${({ isDisabled }: StyleProps) =>
    isDisabled &&
    css`
      background-color: #f2f2f2 !important;
      border: 1px solid #e8e8e8 !important;
      color: #1c1c1c;
      cursor: not-allowed;
      opacity: 1;
    `};
`;

const customStyles = ({
  sm,
  isDisabled,
  menuPosition,
  bg,
  height,
  error,
}: StyleProps) => ({
  control: (base: any) => ({
    ...base,
    color: isDisabled ? "#1c1c1c" : "#1c1c1c",
    background: isDisabled ? "#f2f2f2" : bg ? bg : "#ffffff",
    borderRadius: "8px",
    minHeight: sm ? "28px" : "35.5px",
    fontWeight: "400",
    height: height,
    fontSize: "14px",
    width: "100%",
    opacity: isDisabled ? 1 : 1,
    border: error
      ? "1px solid #F96565"
      : isDisabled
      ? "1px solid #e8e8e8"
      : "1px solid #e8e8e8",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.06)",
    cursor: isDisabled ? "not-allowed" : "pointer",
    "&:hover": {
      border: "1px solid #E8E8E8 !important",
    },
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "black",
  }),
  optionColor: (state: any) => ({
    width: "20px",
    height: "20px",
    border: "1px solid #ccc",
    backgroundColor: state.data.color,
  }),
  controlColor: (state: string) => ({
    width: "20px",
    height: "20px",
    border: "1px solid #ccc",
    marginLeft: "10px",
    backgroundColor: state ? state : "white",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none",
  }),
  menu: (base: any) => ({
    ...base,
    position: menuPosition,
    zIndex: 100,
  }),
});

export { customStyles, SelectWrapper };
