import { Fragment, useCallback } from "react";
import { Col, Row } from "react-grid-system";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hook";
import { logOut } from "app/slices/authSlices/authSlices";
import { Auth, PROFILE } from "shared/constants/path";
import profileSetting from "assets/icons/profile_setting.svg";
import payment from "assets/icons/paybent_method.svg";
import logout from "assets/icons/logout.svg";
import { BaseButton, BaseModal, Flex, MenuItem } from "components";
import { authData } from "app/states/auth";
import { get } from "lodash";
import { UserRole } from "shared/constants/permissions";
import { useTranslation } from "react-i18next";
import { PROFILE_CONSTANTS } from "modules/profile/constants";
import { useDisclosure } from "@mantine/hooks";
import { GLOBAL_CONSTANTS } from "modules/globalConstants";
const ProfileLayout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const LogOut = useCallback(() => {
    dispatch(logOut());
    navigate(`/${Auth.LOGIN}`, { replace: true });
  }, [dispatch, navigate]);
  const arr = [
    {
      name: t(PROFILE_CONSTANTS.MY_ACCAUNT),
      image: profileSetting,
      url: PROFILE.MY_PROFILE,
    },
    // {
    //   name: t(PROFILE_CONSTANTS.REASONABLE_PRICE),
    //   image: payment,
    //   url: PROFILE.PAYMENT_METHOD,
    // },
  ];
  const userAuthData = useAppSelector(authData);
  const user = get(userAuthData, "userData.data.role", false);
  if (user === UserRole.ADMIN) {
    arr.pop();
  }
  return (
    <Fragment>
      <BaseModal
        close={close}
        isDelete={true}
        opened={opened}
        title={"Are you sure you want to log out?"}
      >
        <Flex justify={"end"} gap={"10px"}>
          <BaseButton handleClick={close}>
            {t(GLOBAL_CONSTANTS.CANSEL)}
          </BaseButton>
          <BaseButton
            className="ml-8"
            handleClick={() => {
              LogOut();
            }}
            danger
          >
            {t(GLOBAL_CONSTANTS.YES)}
          </BaseButton>
        </Flex>
      </BaseModal>
      <Row >
        <Col xs={2} style={{ marginTop: '35px' }} >
          <Col xs={12} style={{ gap: "10px", padding: "0 10px" }}>
            {arr.map((item: any, index: number) => {
              return (
                <MenuItem
                  key={get(item, "url", index + 1)}
                  url={item.url}
                  activeUrls={item.url}
                  icon={item.image as any}
                >
                  {item.name}
                </MenuItem>
              );
            })}
            <MenuItem
              key={"22"}
              url={"logout"}
              onClick={open}
              icon={logout as any}
            >
              {t(PROFILE_CONSTANTS.LOGOUT)}
            </MenuItem>
          </Col>
        </Col>
        <Col xs={10} style={{ padding: 0 }}>
          {/* <Bredcrump className="mt-8 ml-8" items={items} /> */}
          <Outlet />
        </Col>
      </Row>
    </Fragment>
  );
};

export default ProfileLayout;
