import styled from "styled-components";
import { StyledFormInputProps } from "./types";

export const StyledFormInput = styled.div<StyledFormInputProps>`
  input {
    text-align: ${({ TACeonter }: StyledFormInputProps) => TACeonter ? "center" : "left"};
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 8px 12px 8px ${({ textPosition }: StyledFormInputProps) => textPosition || "12px"};
    /* outline: none; */
    font-size: 14px;
    font-weight: ${({ textPosition }: StyledFormInputProps) => (textPosition ? "500" : "400")};
    margin: ${({ margin }: StyledFormInputProps) => margin || "0px"};
    /* min-width: 340px; */
    color: #1c1c1c;
    display: inline-block;

    max-width: ${({ width }: StyledFormInputProps) => width || "100%"};
    width: ${({ width }: StyledFormInputProps) => width || "100%"};
    min-height: ${({ height }: StyledFormInputProps) => height || "40px"};
    /* background-color: ${({ bg }: any) =>
    bg ? "#F1F1F1F1" : "#F1F1F1F1"}; */
    background-color: ${({ bg }: StyledFormInputProps) => (bg ? "white" : "#F2F2F2")};
    border: ${({ error }: StyledFormInputProps) =>
    error ? "1px solid #F96565" : "1px solid #e8e8e8"};
    &:disabled {
      background-color: #f2f2f2 !important;
      border: ${({ error }: StyledFormInputProps) =>
    error ? "1px solid #F96565" : "1px solid #e8e8e8"} !important;
      color: #1c1c1c;
      cursor: not-allowed;
      opacity: 1;
    }
    &::placeholder {
      color: #979797;
      text-align: ${({ TACeonter }: StyledFormInputProps) => TACeonter ? "center" : "left"};
    }
  }
  .mantine-NumberInput-controls {
    display: none !important;
  }

  label {
    font-weight: 500;
    font-size: 12px;
    margin-left: 10px;
    color: ${({ error }: StyledFormInputProps) => (error ? "#F96565" : "#1c1c1c")};
    /* width: 100%; */
    /* tr: ; */
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: inherit; */
  }

  span {
    display: inline-block;
    font-size: 12px;
    color: #F96565;
  }
`;
