import { memo } from "react";
import { ReactSVG } from "react-svg";
import Text from "components/Text";
import inbox from "assets/icons/inbox.svg";
import { useTranslation } from "react-i18next";
import { NODATA } from "./constants";
const Nodata = ({ colLength }: { colLength: number }) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td colSpan={colLength} style={{ opacity: 0.6, border: "none" }}>
        <ReactSVG
          src={inbox as any}
          style={{
            fontSize: "8vh",
            opacity: 0.5,
          }}
        />
        <Text>{t(NODATA.NO_DATA)}</Text>
      </td>
    </tr>
  );
};

export default memo(Nodata);
