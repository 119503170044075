export const AUTH_CONSTANTS = {
  EMAIL: "email",
  PASSWORD: "password",
  LOGIN: "login",
  FORGOT_PASSWORD: "forgot_password",
  ADMIN_PANEL: "admin_panel",
  UPDATE_PASSWORD: "update_password",
  WE_SEND_MASSAGE: "we_send_massage",
  SUBMIT: "submit",
  CANCEL: "cancel",
  SET_YOUR_PASSWORD: "set_your_password",
  CONFIRM_PASSWORD: "confirm_password",
  CONFIRM: "confirm",
  CODE: "code"
};
