export const HOMECONSTANT = {
  TITLE: "analytics",
  DISCRIPTION: "discrition",
  ALL_SALES_SUMMARY:"all_sales_summary",
  CHARTS_TITLE: "charts_title",
  SALES_THIS_MONTH: "sales_this_month",
  LAST_SENT_PUSH_POTIFICATIONS: "last_sent_push_potifications",
  STATUS: "stats",
  TOTAL_STUDENTS: "total_sudents",
  TOTAL_SESSIONS: "total_sessions",
  NEW_STUDENTS_DAILY: "new_students_daily",
  NEW_STUDENTS_MONTHLY: "new_students_monthly",
  SESSIONS_FREE: "sessions_free",
  MONTHLY:"monthly",
  SESSIONS_PAID: "sessions_paid",
  OPENED: "opened",
  DELIVERED: "delivered",
};
