import { createSelector } from "@reduxjs/toolkit";
import { selectHomeData, selectHomeTotal } from "../selectors/home";
import moment from "moment";
import { get } from "lodash";
export const homeData = createSelector(
    selectHomeData,
    (data) => {
        const monthNames = [
            { name: 'Jan', fullName: 'January' },
            { name: 'Feb', fullName: 'February' },
            { name: 'Mar', fullName: 'March' },
            { name: 'Apr', fullName: 'April' },
            { name: 'May', fullName: 'May' },
            { name: 'Jun', fullName: "June" },
            { name: 'Jul', fullName: 'July' },
            { name: 'Aug', fullName: 'August' },
            { name: 'Sep', fullName: 'September' },
            { name: 'Oct', fullName: 'October' },
            { name: 'Nov', fullName: 'November' },
            { name: 'Dec', fullName: 'December' }
        ];

        const charts = get(data, 'data.charts', []).map((item: any) => {
            return {
                // ...item,
                yyyymm: monthNames[moment(get(item, "yyyymm", "")).month()],
                year: moment(get(item, "yyyymm", "")).year(),
                UZS: get(item, "uzs", 0),
                USD: get(item, "usd", 0),
                yyyymmName: get(item, "yyyymm", "")
            }
        });

        return {
            ...data,
            data: {
                ...data?.data,
                charts: charts
            }
        }
    }
);
export const totals = createSelector([selectHomeTotal], (data) => data);
