import styled from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItem = styled.div<StyledMenuItemProps>`
width: ${({ width }: { width?: string | any }) => width && width}!important;
  .menu-link {
    display: flex;
    align-items: center;
    border-radius: 8px;
    /* padding: 10px 20px; */
    text-decoration: none;
    width: ${({ width }: { width?: string | any }) => width && width} !important;
    margin-top: 10px;
    .w-100 {
      width: 100%;
    }

    
    .menu-icon {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
    .stroke {
      width: 22px;
      height: 20px;
      margin-right: 15px;
    }
    .active {
      background: #e5f3ff;
      border-radius: 8px;
      .stroke {
        svg {
          path {
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
            stroke: #0085ff;

            /* fill: #0085ff; */
          }
        }
      }
      .menu-icon {
        svg {
          path {
            fill: #0085ff;
            /* stroke: #0085ff; */
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
          }
        }
      }
      .menu-text {
        color: #0085ff;
        font-weight: 700;
      }
    }
  }
`;
