import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import ru from "./ru";
import uz from "./uz";
import en from "./en";

// language
i18next.use(initReactI18next).init({
  // fallbackLng: "uz",
  // fallbackLng: "ru",
  fallbackLng: "en",
  debug: true,
  resources: {
    ru: {
      translation: ru,
    },
    uz: {
      translation: uz,
    },
    en: {
      translation: en,
    }
  },
});
