import { BaseButton, BaseInput, BasePagination, BaseSelect, BaseTable, Flex, FormInput, Loading, WindowHeight } from "components";

import { Col, Row } from "react-grid-system";
import { Text } from "components";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { PUSH_NOTEFICATION } from "modules/push/constants";
import moment from "moment";
import { ReactSVG } from "react-svg";
import dots from 'assets/icons/more_horiz.svg'
import Nodata from "components/Nodata/Nodata";
import useConnect from "./connectHistory";

function HistoryPage() {
  const { }  = useConnect()
  const { t } = useTranslation();
  return (
    <>
      <Row justify="between" style={{ padding: '10px 30px' }}>
        <Flex gap="10px">
          <BaseInput
            handleInput={(e: string) => {}}
            // value={filter.search}
            width="300px"
            bg
            // placeholder={t(USERES_PAGE.SEARCH_INPUT)}
             />

          <BaseSelect
            width="100px"
            bg
            handleChange={(e: string) =>{}}
            // value={filter.status}
            placeholder={t(PUSH_NOTEFICATION.STATUS)}
            options={[
              { label: t(PUSH_NOTEFICATION.ALL), value: "ALL" },
              { label: t(PUSH_NOTEFICATION.ACTIVE), value: "ACTIVE" },
              { label: t(PUSH_NOTEFICATION.INACTIVE), value: "INACTIVE" },
            ]} 
            />
        </Flex>
        <Flex gap="10px">
          <BaseButton primary>{t(PUSH_NOTEFICATION.APPLY)}</BaseButton>
          <BaseButton  danger>{t(PUSH_NOTEFICATION.RESET)}</BaseButton>
        </Flex>
      </Row>
      <hr style={{ margin: '0 -20px' }} />
      <Row>
        <Col xs={12}>
          <WindowHeight manual="19.5">
            {get(null, "loading", false) ? (
              <Loading />
            ) : (
              <BaseTable
                className="table_scroll"
                // hasDelete={ability.can(Permissions.DELETE, PAGES.SPARES)}
                // hasUpdate={ability.can(Permissions.UPDATE, PAGES.SPARES)}
                tableHeader={[
                  "ID",
                  t(PUSH_NOTEFICATION.USER_TYPE),
                  t(PUSH_NOTEFICATION.TITLE),
                  t(PUSH_NOTEFICATION.STATUS),
                  t(PUSH_NOTEFICATION.SENT_DATE),
                  t(PUSH_NOTEFICATION.DELIVRIES),
                  t(PUSH_NOTEFICATION.OPENS),
                  t(PUSH_NOTEFICATION.ACTIONS),
                ]}
              >
                {!isEmpty(get(null, "data", [])) ? (
                  get(null, "data", []).map((item: any, index: number) => (
                    <tr
                      className="pointer"
                      key={item.id}

                    >
                      <td>
                        {get(item, 'id', '')}
                      </td>
                      <td>
                        {get(item, "email", null)
                          ? get(item, "email", "-")
                          : "-"}
                      </td>
                      <td>{get(item, "free_session", "")}</td>
                      <td>{get(item, "paid_session", "")}</td>

                      <td>
                        <Text >{moment(get(item, "created_at", "")).format("YYYY.MM.DD")}</Text> <br />
                        <Text style={{ opacity: 0.5 }} small xs>{moment(get(item, "created_at", "")).format("hh:mm A")}</Text>

                      </td>
                      <td>{!get(item, "last_active", null) ? '-' : <>
                        <Text >{moment(get(item, "last_active", "")).format("YYYY.MM.DD")}</Text> <br />
                        <Text style={{ opacity: 0.5 }} small xs>{moment(get(item, "last_active", "")).format("hh:mm A")}</Text>
                      </>}
                      </td>
                      <td style={{ color: get(item, "status", "INACTIVE") === "ACTIVE" ? "green" : "#F79E1B" }}>{t(get(item, "status", "INACTIVE"))}</td>
                      <td>
                        <ReactSVG src={dots as any} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <Nodata colLength={8} />
                )}
              </BaseTable>
            )}
          </WindowHeight>
        </Col>
        <Col xs={12} style={{ marginTop: "10px", alignItems: "center", justifyContent: 'space-between', display: "flex" }}>
          <Flex gap="10px">
            <Text>{t(PUSH_NOTEFICATION.SHOW)}</Text>
            <BaseSelect
              placement="top"
              width="80px"
              // value={filter.size}
              // handleChange={(val: number) => handleFilter({ name: 'size', value: val })}
              bg placeholder={t(PUSH_NOTEFICATION.STATUS)} options={[
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 },
              ]} />
            <Text>{t(PUSH_NOTEFICATION.ON_THE_PAGE)}</Text>
          </Flex>
          <BasePagination
            value={1}
            total={3}
            handleChange={()=>{}} />
        </Col>
      </Row>
    </>
  );
}

export default HistoryPage;