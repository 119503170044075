import styled from "styled-components";
import "react-circular-progressbar/dist/styles.css";

const StyledBaseProgress = styled.div`
  width: 64px;
  height: 64px;
  font-size: 16px;
  font-weight: 500;
`;

export { StyledBaseProgress };
