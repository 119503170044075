import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { isEmpty } from "lodash";
import Text from "components/Text";
import Flex from "components/Flex";
import BaseBadge from "components/BaseBadge";
import { MenuItemProps } from "./types";
import { StyledMenuItem } from "./style";
const MenuItem: React.FC<MenuItemProps> = ({
  children,
  width,
  icon,
  url = "/",
  activeUrls = [],
  stoke = false,
  badge,
  count = 0,
  exact = false,
  active = false,
  ...props
}) => {
  const { pathname } = useLocation();

  const urlFunction = () => {
    let element = "";
    pathname.split("/").map((item, index) => {
      if (index != 0) {
        element += "/" + item;
      }
    });
    return element;
  };
  return (
    <StyledMenuItem width={width} {...props}>
      {isEmpty(activeUrls) ? (
        <NavLink style={{ width: '100%' }}  className={`menu-link `} to={url === "logout" ? "" : url}>
          <Flex
            justify={"space-between"}
            className="w-100"
            style={{ alignItems: "center", height: "20px", padding: "20px" }}
          >
            <Flex
              // justify="center"
              align="center"
              className={`${String(urlFunction()) == String(url) && "active"} w-100`}
              style={{
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <ReactSVG className={stoke ? "stroke" : "menu-icon"} src={icon} />
              <Text className={"menu-text text-center"}>{children}</Text>
            </Flex>
            {badge && (
              <BaseBadge lg={true} primary={true}>
                {count}
              </BaseBadge>
            )}
          </Flex>
        </NavLink>
      ) : (
        <NavLink  style={{ width: '100%' }} className={`menu-link `} to={url == "logout" ? "" : url}>
          <Flex className="w-100" justify={"space-between"}>
            <Flex
              // justify="center"
              align="center"
              className={`${String(urlFunction()) == String(url) && "active"} w-100`}
              style={{  height: "20px", padding: "20px" }}
            >
              <ReactSVG className={stoke ? "stroke" : "menu-icon"} src={icon} />
              <Text
                className={"menu-text text-center"}
                style={{ height: "20px", alignItems: "center" }}
              >
                {children}
              </Text>
            </Flex>
            {badge && (
              <BaseBadge lg primary>
                {count}
              </BaseBadge>
            )}
          </Flex>
        </NavLink>
      )}
    </StyledMenuItem>
  );
};

export default MenuItem;
