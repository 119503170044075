import { AuthNav, Content } from "components";
import ScrollToTop from "routers/ScrollToTop";
import { Main } from "shared/constants/path";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { Container } from "react-grid-system";
import AuthContainer from "components/AuthContainer";

const contentStyle = {
  maxWidth: "100%",
  width: "80%",
  background: "#fff",
  height: "60vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "spase-between",
  padding: 0,
  borderRadius: "12px",
};

const AuthLayout = () => {
  const isAuthenticated = false;
  useEffect(() => {
    const hasRefreshed = localStorage.getItem("hasRefreshed");

    if (!hasRefreshed) {
      localStorage.setItem("hasRefreshed", "true");
      window.location.reload();
    }
  }, []);
  return (
    <ScrollToTop>
      {!isAuthenticated ? (
        <Fragment >
          {/* <AuthContainer> */}
          <Container style={{
            marginTop: '40px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }} >
            <Content style={contentStyle}>
              <AuthNav />
              <Outlet />
            </Content>
          </Container >
          {/* </AuthContainer> */}
        </Fragment>
      ) : (
        <Navigate to={Main.HOME} replace />
      )}
    </ScrollToTop>
  );
};

export default AuthLayout;
