import right from "assets/icons/arrow_right_navigation.svg";
import { Skeleton } from '@mantine/core'
import { StyledBaseBreadcrumbLoading } from './style'
import { ReactSVG } from 'react-svg'

interface PropsType {
    length: number
}
function index({ length = 2 }: PropsType) {
    return (
        <StyledBaseBreadcrumbLoading>
            {
                Array.from({ length: length }).map((_, index) => (
                    <li key={index}>
                        <Skeleton height={20} width={100} style={{ marginTop: '3px' }} />
                        {length !== index + 1 && <ReactSVG
                            className="arrow"
                            style={{ width: "20px" }}
                            src={right as any}
                        />}
                    </li>
                ))
            }
        </StyledBaseBreadcrumbLoading>
    )
}

export default index