import request from "..";

class homeServices {
    static dashboard = () => {
        return request.get(`/api/dashboard`);
    };
    static dashboardStats = () => {
        return request.get(`/api/dashboard/stats`);
    };
    static dashboardUsers = () => {
        return request.get(`/api/dashboard/user`);
    };
    static dashboardSession = () => {
        return request.get(`/api/dashboard/session`);
    };
}

export default homeServices;
