import { BaseButton, Text } from "components";
import FormInput from "components/FormInput";
import { Col, Container, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FormWrapper } from "../login/style";
import useConnect from "../login/connect";
import { useTranslation } from "react-i18next";
import { AUTH_CONSTANTS } from "../constants";

const StyledLoginForm = styled.div`
  width: 50%;
  margin: 0 auto;
  .row {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 800px) {
    width: 80%;
    margin: 0 auto;
    justify-items: center;
  }
  @media only screen and (min-width: 800px) {
    width: 80%;
    margin: 0 auto;
    justify-items: center;
  }
`;
const Texty = styled(Text)`
  @media only screen and (max-width: 800px) {
    margin-left: 50px;
  }
  @media only screen and (min-width: 800px) {
    width: 100%;
  }
`;

const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  const { handleSubmit } = useConnect();
  const { t } = useTranslation();
  function setFieldValue(arg0: string, arg1: string) {
    throw new Error("Function not implemented.");
  }

  return (
    <StyledLoginForm>
      <Container fluid>
        <Row>
          <Texty
            style={{
              textAlign: "center",
              marginBottom: "40px",
              // marginLeft: "150px",
              margin: "0 auto",
              paddingBottom: "40px",
              fontSize: "30px",
              fontWeight: "500",
              marginTop: "20px",
            }}
            xl
          >
            {t(AUTH_CONSTANTS.SET_YOUR_PASSWORD)}
          </Texty>
        </Row>
        <FormWrapper onSubmit={handleSubmit}>
          <Row>
            <Col
              xs={12}
              className={"mb-16 "}
              style={{
                textAlign: "left",
                display: "flex",
                justifyContent: "spase-between",
              }}
            >
              <Col xs={4} style={{ textAlign: "left", marginTop: "8px" }}>
                <Text style={{ fontSize: "14px", fontWeight: "500" }}>
                  {t(AUTH_CONSTANTS.PASSWORD)}
                </Text>
              </Col>
              <Col
                xs={9}
                style={{
                  maxWidth: "400px",
                  borderRadius: "8px",
                }}
              >
                <FormInput
                  name={"password"}
                  type={"password"}
                  bg={"white"}
                  handleOnChange={function (): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </Col>
            </Col>
            <Col
              xs={12}
              className={"mb-16 "}
              style={{
                textAlign: "left",
                display: "flex",
                justifyContent: "spase-between",
              }}
            >
              <Col xs={4} style={{ textAlign: "left", marginTop: "8px" }}>
                <Text style={{ fontSize: "14px", fontWeight: "500" }}>
                  {t(AUTH_CONSTANTS.CONFIRM_PASSWORD)}
                </Text>
              </Col>
              <Col
                xs={9}
                style={{
                  maxWidth: "400px",
                  borderRadius: "8px",
                }}
              >
                <FormInput
                  name={"password"}
                  type={"password"}
                  bg={"white"}
                  handleOnChange={function (): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </Col>
            </Col>
            <Col xs={12}  className={"mb-32"}>
              <BaseButton
                style={{ marginTop: "20px", width: "163px", height: "40px" }}
                type={"submit"}
                primary
                medium
                onClick={() => navigate("/")}
              >
                {t(AUTH_CONSTANTS.CONFIRM)}
              </BaseButton>
            </Col>
          </Row>
        </FormWrapper>
      </Container>
    </StyledLoginForm>
  );
};

export default ForgetPasswordPage;
