import React, { useState } from "react";
import { Col, Row } from "react-grid-system";
import { FormInputProps } from "./types";
import { StyledFormInput } from "./style";
import { Input, NumberInput } from "@mantine/core";
import { ReactSVG } from "react-svg";
import status from "assets/icons/inputStatus.svg";
import noStatus from "assets/icons/inputnotStatus.svg";
const FormInput: React.FC<FormInputProps> = ({
  label,
  placeholder,
  value = "",
  handleOnChange,
  type,
  handleOnBlur,
  rightSection,
  error,
  name,
  className,
  defaultValue,
  disabled = false,
  left = 4,
  right = 12,
  id,
  onKeyDown,
  bg,
  ...props
}) => {
  const [inputType, setInputType] = useState(false);
  
  return (
    <StyledFormInput {...props} bg={bg} error={error} className={className}>
      <Row align={"center"}>{label&&
        <Col xs={12} style={{ textAlign: "left" }} justify-content={"center"}>
          <label htmlFor={id}>{label}</label>
        </Col>}
        <Col xs={right}>
          {type == "number" ? (
            <NumberInput
              thousandSeparator=","
              id={id}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              rightSection={<span style={{marginTop:'1px', marginRight: '10px', color: '#1C1C1C' }}>{rightSection}</span>}
              value={value ? value : ""}
              // error={error}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              name={name}
              defaultValue={defaultValue}
              disabled={disabled}
            />
          ) : (
            <>
              <Input
                id={id}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                value={value ? value : ""}
                onChange={handleOnChange}
                type={inputType ? "text" : type}
                onBlur={handleOnBlur}
                name={name}
                defaultValue={defaultValue}
                disabled={disabled}
              />
              {type == "password" && (
                <ReactSVG
                  onClick={() => setInputType(!inputType)}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    // marginRight: '10px',
                    right: "30px",

                    marginTop: "-28px",
                  }}
                  src={inputType ? noStatus : status as any}
                />
              )}
            </>
          )}
        </Col>
        {/* <Col xs={12}>{error && <span className=" ml-8">{error}</span>}</Col> */}
      </Row>
    </StyledFormInput>
  );
};

export default FormInput;
