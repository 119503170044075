import styled from "styled-components";

export const StyledLoginForm = styled.div`
  /* width: 50%;
  margin: 0 auto;
  justify-items: center;
  margin-top: 10vh;
  .row {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 800px) {
    width: 80%;
    margin: 0 auto;
    justify-items: center;
    margin-top: 10vh;
  }
  @media only screen and (min-width: 800px) {
    width: 80%;
    margin: 0 auto;
    justify-items: center;
    margin-top: 10vh;
  } */
`;
export const FormWrapper = styled.form`
  /* display: flex; */
  width: 100%;
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
`;
