import { LoadingStyle } from "./style";
import { ClipLoader } from "react-spinners";
interface propsType {
  h?: string;
}
{
  /* <ColorRing
  visible={true}
  height="80"
  width="80"
  ariaLabel="color-ring-loading"
  wrapperStyle={{}}
  wrapperClass="color-ring-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
  /> */
}

function index({ h }: propsType) {
  return (
    <LoadingStyle h={h}>
      {/* <Loader
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
        color="blue"
      /> */}
      <ClipLoader  color="##44a2fa" />
    </LoadingStyle>
  );
}

export default index;
