import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchGetNotfcations, handlePushFilterClear } from "app/slices/pushSlices/pushSlices";
import { notficationsData, pushFilter } from "app/states/push";
import { useCallback, useEffect } from "react";


const useConnect = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(pushFilter);
  const data = useAppSelector(notficationsData);
  const handleFetchApply = useCallback(() => {
    dispatch(fetchGetNotfcations(filter))
  }, [filter]);
  const handleFilterClear = useCallback(() => {
    dispatch(fetchGetNotfcations({ page: 0, size: 10 }));
    dispatch(handlePushFilterClear());
  }, [filter]);
  const fetchGetNotfcationsHistory = useCallback(() => {
    dispatch(fetchGetNotfcations(filter))
  }, [filter.page, filter.size]);
  useEffect(() => {
    fetchGetNotfcationsHistory()
  }, [fetchGetNotfcationsHistory]);
  return {
    handleFilterClear,
    handleFetchApply,
    filter,
    data
  };
};
export default useConnect;