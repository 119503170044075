import home from "./home.json";
import global from "./global.json";
import profile from "./profile.json";
import users from "./users.json";
import speaking from "./speaking.json";
import push from "./push.json";
const ru = {
  ...home,
  ...global,
  ...profile,
  ...users,
  ...speaking,
  ...push,
};
export default ru;
