import AuthServices from "services/api/auth";
import ProfileServices from "services/api/profile";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
// Define the type for the parameters passed to logIn
interface LogInParams {
  email: string;
  password: string;
}

// Define the type for the response data
interface LogInResponse {
  status: number;
  data: {
    token: string;
    [key: string]: any; // Include any other properties that might be part of the response
  };
}

// Define the type for the thunkAPI.rejectWithValue
interface ThunkApiConfig {
  rejectValue: string;
}

const initialState: any = {
  loading: false,
  isAuthenticated: false,
  token: "",
  userData: {},
  error: "",
};

export const logIn = createAsyncThunk<
  LogInResponse,
  LogInParams,
  ThunkApiConfig
>("auth/logIn", async (params, thunkAPI) => {
  try {
    const request = await AuthServices.Login(params);
    if (get(request, "status", "") != 200) {
      return thunkAPI.rejectWithValue(get(request, "message", ""));
    }
    const respond = await request.data;
    return respond;
  } catch (err: Error | any) {
    return thunkAPI.rejectWithValue(err.message);
  }
});
export const profileInfo = createAsyncThunk<
  LogInResponse,
  void,
  ThunkApiConfig
>("auth/profile", async (_, thunkAPI) => {
  try {
    const request = await ProfileServices.MyAccount();
    if (get(request, "status", "") != 200) {
      return thunkAPI.rejectWithValue(get(request, "message", ""));
    }
    const respond = await request.data;
    return respond;
  } catch (err: Error | any) {
    return thunkAPI.rejectWithValue(err.message);
  }
});



const authSlices = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logIn.fulfilled, (state, actions) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.token = get(actions, "payload.data", "");
      state.userData = get(actions, "payload", "");
    });
    builder.addCase(logIn.rejected, (state, actions) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.token = "";
      state.userData = {};
      state.error = get(actions, "error.message", "");
    });
    // profile info
    builder.addCase(profileInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(profileInfo.fulfilled, (state, actions) => {
      state.isAuthenticated = true;
      state.userData = get(actions, "payload", "");
    });
    builder.addCase(profileInfo.rejected, (state, actions) => {
      state.loading = false;
      state.userData = {};
      state.error = get(actions, "error.message", "");
    });
  },
});

export const { logOut } = authSlices.actions;
export default authSlices.reducer;