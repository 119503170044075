import { StyledFormTextarea } from "./style";

const BaseTextarea = ({
  label,
  name,
  placeholder,
  validation,
  error,
  width,
  center = "flex-start",
  defaultValue = "",
  disabled = false,
  height,
  bg = false,
  value,
  handleChange,
  left = 4,
  right = 8,
  onBlur,
  ...props
}: any) => {
  return (
    <StyledFormTextarea
      error={error}
      {...props}
      height={height}
      width={width}
      bg={bg}
    >
      <textarea
        value={value}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={onBlur}
      />
    </StyledFormTextarea>
  );
};

export default BaseTextarea;
