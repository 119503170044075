import { memo } from "react";
import { ReactSVG } from "react-svg";
import settingsIcon from "assets/icons/settings.svg";
import { PropsProfile } from "./type";
import { NavLink } from "react-router-dom";
import { Avatar, Flex } from "@mantine/core";
import { Main } from "shared/constants/path";
import { ProfileContainerWrap } from "./style";
import Translate from "components/Translate/Translate";

const Profile = ({ userName = "James Bond" }: PropsProfile) => {
  return (
    <ProfileContainerWrap>
      <Flex justify={"space-between"} align={"center"} style={{ gap: "10px" }}>
        {/* <Translate />  language */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              color: "#1C1C1C",
            }}
          >
            {/* <Avatar
              src="https://i.pravatar.cc/300"
              style={{
                border: "1px solid #E8E8E8",
                marginRight: "10px",
                background: "#fff",
              }}
            />
            <span
              style={{
                marginRight: "10px",
                position: "absolute",
                zIndex: 1,
                top: "27px",
                left: "25px",
                width: "12px",
                height: "12px",
                background: "#00BA34",
                borderRadius: "50%",
              }}
            ></span>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Inter",
                lineHeight: "24px",
                color: "#1C1C1C",
              }}
            ></span> */}
            {userName}
            {/* James Bond */}
          </span>
        </div>
        <NavLink className="profile-settings" to={Main.PROFILE}>
          <ReactSVG src={settingsIcon as any} className={"svg_icon "} />
        </NavLink>
      </Flex>
    </ProfileContainerWrap>
  );
};

export default memo(Profile);
