

export const WRITING_PAGE = {
    WRITING: "writing",
    EDIT: "edit",
    ADD: "add",
    APPLY: "apply",
    RESET: "reset",
    TITLE: "title",
    MESSAGE: "message",
    THUMBNAIL: "thumbnail",
    TEST: "test",
    UPLOAD_IMAGE: "upload_image",
    SEND_TEST: "send_test",
    ACTIONS: "actions",
    SHOW: "show",
    ON_THE_PAGE: "on_the_page",
    SENT_DATE: "sent_date",
    DELIVRIES: "delivries",
    OPENS: "opens",
    STATUS: "status",
    TOTAL: "total",
    FREE: "free",
    PAID: "paid",
    FREE_FAILRD: "free_failed",
    PAID_FAILRD: "paid_failed",
    FREE_IN_24_HOURS: "free_in_24_hours",
    PAID_IN_24_HOURS: "paid_in_24_hours",
    SENT_DATE_AND_TIME: "sent_date_and_time",
    USER: "user",
    DATE: 'date',
    PAYMENT_TYPE:'payment_type',
    AMOUNT: "amount",
    TOTAL_USERS: 'total_users',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    SEARCH_INPUT: 'search_input',
    EMAIL: "email",
    SESSIONS: "sessions",
    SESSION_TYPE: "session_type",
    SESSIONS_FREE: "sessions_free",
    SESSION_PAID: "sessions_paid",
    SESSIONS_FREE_IN_24_HOURS: "sessions_free_in_24_hours",
}