import { useAppDispatch, useAppSelector } from "app/hook";
import {  fetchGetAllSpeaking, fetchGetTotalSpeaking, handleClearSpeaking, handleFilterSpeaking } from "app/slices/speakingSlices/speakingSlices";
import { getAllSpeaking, SpeakingFilter, SpeakingTotals } from "app/states/speaking";
import { useCallback, useEffect } from "react";

const useConnect = () => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector(SpeakingFilter);
    const data = useAppSelector(getAllSpeaking);
    const totals = useAppSelector(SpeakingTotals);
    const fetchSpeaking = useCallback(() => {
        dispatch(fetchGetAllSpeaking(filter));

    }, [filter.page, filter.size]);
    const fetchFilterSessions = useCallback(() => {
        dispatch(fetchGetAllSpeaking(filter));
    }, [filter]);
    // const handleFetchFilter = 
    const handleFilter = useCallback(({ name, value }: { name: string, value: number | string }) => {
        dispatch(handleFilterSpeaking({ name, value }));
    }, []);
    const handleReset = useCallback(() => {
        dispatch(handleClearSpeaking());
        dispatch(fetchGetAllSpeaking({ ...filter, search: '', status: '' }));
    }, [filter]);

    useEffect(() => {
        fetchSpeaking();
        dispatch(fetchGetTotalSpeaking());
    }, [fetchSpeaking]);
    useEffect(() => {
        return () => {
            dispatch(handleClearSpeaking());
            dispatch(handleFilterSpeaking({ name: "page", value: 0 }));
            dispatch(handleFilterSpeaking({ name: "size", value: 10 }));
        }
    }, []);

    return {
        data,
        filter,
        handleFilter,
        fetchFilterSessions,
        handleReset,
        totals
    };
}
export default useConnect;