import React, { createContext, useContext } from 'react';
import { AbilityType, useDefineAbilityFor  } from './ability';

const AbilityContext = createContext<AbilityType | null | any>(null);

export const AbilityProvider = ({ children }: { children: React.ReactNode }) => {
  const ability = useDefineAbilityFor (); // check and manage user
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export const useAbility = () => {
  const context = useContext(AbilityContext);
  if (!context) {
    throw new Error('useAbility must be used within an AbilityProvider');
  }
  return context;
};
